import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useRef, useState } from "react";
import { Comment, InsertRecordingComment } from "../../types/recording";
import { formatSecondsToTime } from "../../utils/dateTimeUtils";
import { Button } from "@progress/kendo-react-buttons";
import useAuth from "../../hooks/useAuth";
import recordingsService from "../../services/recordings.service";
import CustomFormMention from "../custom/form/CustomFormMention";
import useMasterData from "../../hooks/useMasterData";
import moment from "moment";
import "moment/min/locales";
import notificationService from "../../services/notification.service";
import {
  fetchUsersFromCommentText,
  filterCommentText,
} from "./RecordingCommentsHelperFunctions";
import useSwal from "../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import { AccessPermissionEnum } from "../../enums";
import { TinyUser } from "../../types/user";
import { Error } from "@progress/kendo-react-labels";
import { Notification } from "@progress/kendo-react-notification";
import { Notification as NotificationProps } from "../../types/notification/Notification";

interface TinyUserSelect extends TinyUser {
  checked?: boolean;
}

interface AddRecordingCommentProps {
  recordingId: number;
  regionSelection: { start: number; end: number };
  recordingDuration: number;
  shared?: boolean;
  onAddComment: (newComment: Comment) => void;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  platformCallDetailId?: string;
  internalUsers: TinyUserSelect[];
  recordingPath: string;
  setTotalCommentsTemp: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  totalCommentsTemp?: number;
  baseLicenseId?: number;
}

const AddRecordingComment: React.FC<AddRecordingCommentProps> = ({
  recordingId,
  regionSelection,
  shared,
  recordingDuration,
  onAddComment,
  fetchLabelKeyTranslation,
  platformCallDetailId,
  internalUsers,
  recordingPath,
  setTotalCommentsTemp,
  totalCommentsTemp,
  baseLicenseId,
}) => {
  const auth = useAuth();
  const master = useMasterData();
  const swal = useSwal();
  const addCommentFormRef = useRef<Form | null>(null);
  const commentRegionStartRef = useRef<HTMLInputElement | null>(null);
  const commentRegionEndRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCommentPrivate, setIsCommentPrivate] = useState<boolean>(false);

  const commentValidator = (value: string) => {
    if (!value.trim()) {
      return fetchLabelKeyTranslation("ErrMsgEmptyComment", "Please enter a comment.");
    }
    if (value.length > 1000) {
      return  fetchLabelKeyTranslation("ErrMsgLengthComment","Comment length cannot be more than 1000! ");
    }

    return "";
  };

  const addCommentSubmitHandler = async (dataItem: { [name: string]: any }) => {
    const hostname = window.location.hostname;
    const appUrl =
      hostname === "localhost"
        ? `http://${hostname}:3000`
        : `https://${hostname}`;
    const recordingUrl = `${appUrl}/shared${recordingPath}`;
    const users = fetchUsersFromCommentText(
      dataItem.commentText,
      master?.users
    );
    let regionStart: string | null =
      commentRegionStartRef.current?.value ?? null;
    let regionEnd: string | null = commentRegionEndRef.current?.value ?? null;

    if (
      regionStart &&
      Number(regionStart) === 0 &&
      regionEnd &&
      Number(regionEnd) === recordingDuration
    ) {
      regionStart = null;
      regionEnd = null;
    }

    const newComment: InsertRecordingComment = {
      commentText: isCommentPrivate
        ? filterCommentText(dataItem.commentText)
        : dataItem.commentText,
      segmentStart: regionStart ? Number(regionStart) : null,
      segmentEnd: regionEnd ? Number(regionEnd) : null,
      isPrivate: isCommentPrivate,
      users,
      recordingUrl,
    };

    setLoading(true);
    recordingsService
      .addRecordingComment(recordingId, newComment, shared === true)
      .then((createdComment) => {
        if (!isCommentPrivate) {
          totalCommentsTemp !== undefined &&
            setTotalCommentsTemp(totalCommentsTemp + 1);
        }
        const userNotifications: NotificationProps[] = [];
        if (!isCommentPrivate) {
          users.forEach((user) => {
            userNotifications.push({
              id: 0,
              notificationType: {
                id: 2,
                type: "Tagged",
                localizationKey: "Tagged",
              },
              user: user,
              notificationLink: `${appUrl}/callDetail/${platformCallDetailId}?recordingId=${recordingId}`,
              notifyAt: new Date(moment.utc().format())
                .toISOString()
                .replace("T", " ")
                .replace("Z", "")
                .replace(/\.\d+/, ""),
              seen: false,
            });
          });
        }
        try {
          if (!isCommentPrivate && userNotifications.length > 0)
            notificationService.sendNotifications(userNotifications);
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "success",
            title: fetchLabelKeyTranslation("SwtAltAddedTitle", "Comment Added"),
            confirmButtonText: fetchLabelKeyTranslation("OKText", "OK")
            ,
          };
          swal.fire(swalOptions);
          onAddComment(createdComment);
          const form = addCommentFormRef.current as Form;
          form.resetForm();
        } catch (error) {
          if (error instanceof Error) {
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "error",
              title: fetchLabelKeyTranslation("SwtAltAddedFailedTitle","Oops..."),
              text: fetchLabelKeyTranslation("SwtAltAddedFailedText","Something went wrong! Please Try again...Oops..."),
            };
            swal.fire(swalOptions);
          }
        }
      })
      .catch((err) => {
        if (err instanceof Error) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: fetchLabelKeyTranslation("SwtAltAddedFailedTitle", "Oops..."),
            text: fetchLabelKeyTranslation("SwtAltAddedFailedText","Something went wrong! Please Try again...Oops..."),
          };
          swal.fire(swalOptions);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="col-md-5">
      <div className="chat-in w-100">
        <div className="cmtAdd">
          <div className="cmtAddCmt">
            <Form
              ref={addCommentFormRef}
              onSubmit={addCommentSubmitHandler}
              initialValues={{
                commentText: "",
                commentSegmentStart: formatSecondsToTime(
                  regionSelection.start ? regionSelection.start : 0
                ),
                commentSegmentEnd: formatSecondsToTime(
                  regionSelection.end ? regionSelection.end : 0
                ),
              }}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  {isCommentPrivate && (
                    <Notification
                      className="text-white bg-primary"
                      type={{ style: "warning", icon: true }}
                    >
                      <span>
                        {fetchLabelKeyTranslation("AddCommentPrivateInfoText","This comment will only be visible to you.")}
                      </span>
                    </Notification>
                  )}

                  <Field
                    id={"commentText"}
                    name={"commentText"}
                    disabled={!auth?.accessToken && shared}
                    className="h-autoTextArea"
                    max={1000}
                    autoSize={true}
                    rows={7}
                    showTextLimitHint={false}
                    value={formRenderProps.valueGetter("commentText")}
                    component={CustomFormMention}
                    validator={commentValidator}
                    data={internalUsers}
                    isMention={
                      !isCommentPrivate &&
                      auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)
                    }
                    singleLineInput={false}
                    placeholder={
                      fetchLabelKeyTranslation("AddCommentPlaceholderText","Add comment")
                    }
                  />
                  <div className="cmtAddBtn p-t-10">
                    <div className="cmtPostTime float-left">
                      <div className="d-flex align-items-center">
                        <div className="PostTimediv border-black-3 border-solid border-w-1 padding-6 radius-5">
                          <input
                            type={"hidden"}
                            value={regionSelection.start}
                            ref={commentRegionStartRef}
                          />
                          <span className="fs-13 padding-3">
                            {formatSecondsToTime(
                              regionSelection.start ? regionSelection.start : 0
                            )}
                          </span>
                          <span className="text-muted">/</span>
                          <input
                            type={"hidden"}
                            value={regionSelection.end}
                            ref={commentRegionEndRef}
                          />
                          <span className="fs-13 padding-3">
                            {formatSecondsToTime(
                              regionSelection.end ? regionSelection.end : 0
                            )}
                          </span>
                        </div>
                        <Tooltip
                          anchorElement={"target"}
                          position={"bottom"}
                          parentTitle={true}
                        >
                          <a
                            className="cursor-pointer"
                            title={fetchLabelKeyTranslation("RegionSelectionTextMsg","Select region to update comment region time")}
                          >
                            <i className="bi bi-info-circle fs-16 p-l-5"></i>
                          </a>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="switchPrivate float-left p-l-20 p-t-6">
                      <div className="cmtPostTime float-left">
                        <div className="formBoxLabel fs-14 p-r-3">{fetchLabelKeyTranslation("TextPrivate","Mark Private")}</div>
                      </div>

                      <div className="formBoxAction float-left">
                        <div className="switchButton">
                          <Checkbox
                            className="m-l-2"
                            checked={isCommentPrivate}
                            onChange={(e: CheckboxChangeEvent) => {
                              setIsCommentPrivate(e.value);
                            }}
                            disabled={
                              (!auth?.accessToken && shared === true) ||
                              baseLicenseId === 1
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="cmtPostBt float-right">
                      <Button
                        type={"submit"}
                        className={`btn bg-primary text-white btn-filter pull-right ${
                          (!auth?.accessToken && shared === true) ||
                          !(
                            (baseLicenseId === 1 && totalCommentsTemp === 0) ||
                            baseLicenseId === 2 ||
                            baseLicenseId === 3
                          )
                            ? "disabledBtn"
                            : ""
                        }`}
                        disabled={
                          (!auth?.accessToken && shared === true) ||
                          !(
                            (baseLicenseId === 1 && totalCommentsTemp === 0) ||
                            baseLicenseId === 2 ||
                            baseLicenseId === 3
                          )
                        }
                        id="btnPost"
                      >
                        {loading ? (
                          <Loader
                            size="small"
                            type="infinite-spinner"
                            themeColor="light"
                          />
                        ) : (
                          fetchLabelKeyTranslation("", "Save")
                        )}
                      </Button>
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRecordingComment;
