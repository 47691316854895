import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLocale from "../../hooks/useLocale";
import insightsService from "../../services/insights.service";
import { Dictionary } from "../../types/Dictionary";
import {
  Insight,
  InsightCallDetails,
  InsightCallFilterMetrics,
  InsightMetricKeyLabel,
} from "../../types/insight";
import { InsightFilterMetric } from "./Insights";
import { CallDetail } from "../../types/call";
import { ColorCategory } from "../../types/master-data";
import CallListing from "../../components/custom/CallListing";
import { searchCallData } from "../../utils/callDetailUtil";
import CustomSearchFieldTextInput from "../../components/custom/form/CustomSearchFieldTextInput";
import { useLocation } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { LocalStorageFilter } from "../../types/localstorage-filters/LocalStorageFilter";
import useSwal from "../../hooks/useSwal";
import { Page } from "../../types/Page";
import { CallTableSortColumnEnum } from "../../enums/CallTableSortColumnEnum";
import useTranslation from "../../hooks/useTranslation";

interface InsightCallsGridProps {
  docked?: boolean;
  insight?: Insight;
  viewByMetric: InsightMetricKeyLabel;
  filterMetric?: InsightFilterMetric;
  viewByMetricFilterValue?: {
    metricKeyLabel: string;
    metricKey: string;
    metricSubKeyLabel?: string;
    displayName?: string;
  };
  onUserBackClick: () => void;
}

const initialPageDetail: Page = {
  skip: 0,
  take: 10,
  totalRecords: 0,
  sort: {
    sortField: CallTableSortColumnEnum.DateTime,
    sortDirection: "desc"
  }
}


const InsightCallsGrid: React.FC<InsightCallsGridProps> = ({
  docked,
  insight,
  viewByMetric,
  filterMetric,
  viewByMetricFilterValue,
  onUserBackClick,
}) => {
  const trans=useTranslation("InsightCallsGrid");
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const [calls, setCalls] = useState<InsightCallDetails | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    fetchCalls(initialPageDetail);
  }, [insight, filterMetric, viewByMetric, viewByMetricFilterValue]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "InsightCallsGrid"
      ]
    ) {
      trans.fetchTranslations("InsightCallsGrid");
    }
  }, [localeCtx?.selectedLocale]);

  const fetchCalls = async (page: Page) => {
    try {
      setCalls({id: insight?.id ?? "", page: page, calls: []});
      setLoading(true);
      setError(false);
      if (insight && insight.id) {
        const callFilterMetrics: InsightCallFilterMetrics = {
          insightId: insight.id,
          filterKey: filterMetric ? filterMetric.metricKeyLabel : undefined,
          filterSubKey: filterMetric
            ? filterMetric.metricKeySubLabel
            : undefined,
          filterValue: filterMetric ? filterMetric.metricKey : undefined,
          viewBy: viewByMetricFilterValue
            ? viewByMetricFilterValue.metricKeyLabel
            : undefined,
          viewByFilterSubKey: viewByMetricFilterValue
            ? viewByMetricFilterValue.metricSubKeyLabel
            : undefined,
          viewByFilterValue: viewByMetricFilterValue
            ? viewByMetricFilterValue.metricKey
            : undefined,
        };
        const resp = await insightsService.getInsightCallDetails(callFilterMetrics, page, search);
        const _callDetails: CallDetail[] = [];
        resp?.calls?.forEach((c) => {
          _callDetails.push({ ...c, expanded: false });
        });
        setCalls({id: insight.id, page: resp.page, calls: _callDetails});
      }
    } catch (err) {
      setCalls({id: insight?.id ?? "", page: page, calls: []});
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCallFlagUpdate = async (
    selectedFlag: ColorCategory | null,
    callDetailData: CallDetail
  ) => {
    if (selectedFlag === null) {
      selectedFlag = { id: 0, name: "", hexCode: "" };
    }
    if (calls) {
      var tempCalls = calls.calls.map((cd) => {
        if (cd.platformCallDetailId === callDetailData.platformCallDetailId) {
          cd.colorCategory = selectedFlag !== null ? selectedFlag : undefined;
        }
        return cd;
      });
      setCalls({...calls, calls: tempCalls});
    }
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handlePaginationChange = (pageDetail: Page) => {
    fetchCalls(pageDetail);
  }

  const handleSortChange = (sortField: string, sortDirection: string) => {
    let _pageFilter: Page = {...initialPageDetail};
    _pageFilter.sort = {
      sortDirection: sortDirection,
      sortField: sortField
    }
    fetchCalls(_pageFilter);
  }

  const handleSearchTextOnKeyDown = (event: KeyboardEvent) => {
    if(event.key === "Enter"){
      fetchCalls(initialPageDetail);
    }
  }

  return (
    <React.Fragment>
      <div className="row p-t-10 p-r-20 p-l-20 p-b-1">
        <div className="col-md-12">
          <div className="bg-white d-flex justify-content-between flex-wrap">
            <span className="font-weight-semi d-flex align-items-center">
              {viewByMetricFilterValue
                ? `${
                  trans.fetchLabelKeyTranslation("TextCallsFor", "Calls for")
                  } ${viewByMetricFilterValue.metricKeyLabel} = "${
                    viewByMetricFilterValue.displayName
                      ? viewByMetricFilterValue.displayName
                      : viewByMetricFilterValue.metricKey
                  }${
                    viewByMetricFilterValue.metricKeyLabel === "Topics" &&
                    viewByMetricFilterValue.metricSubKeyLabel !==
                      "ExactMatchTopic"
                      ? " & " + viewByMetricFilterValue.metricSubKeyLabel
                      : ""
                  }"`
                : `${
                  trans.fetchLabelKeyTranslation("TextCalls", "Calls")
                  }`}
            </span>
            <div className="buttons-container hov-transparent d-flex align-itmes-center">
              <div className="searchBox searchCol" style={{ lineHeight: "0" }}>
                <CustomSearchFieldTextInput
                  className="input-search"
                  placeholder={trans.fetchLabelKeyTranslation("SearchPlaceholder", "Search...")}
                  value={search}
                  updateValue={setSearch}
                  searchTextChangeHandler={handleSearchChange}
                  onEscapeKeyFunc={true}
                  handleOnKeyDown={handleSearchTextOnKeyDown}
                />
              </div>
              {viewByMetricFilterValue && (
                <Button
                  className="line-height-1 text-white p-r-1"
                  style={{ height: "30px" }}
                >
                  <Link
                    to={"#"}
                    onClick={onUserBackClick}
                    className="btn-link text-primary p-0 m-0 d-flex align-items-center"
                  >
                    <i className="bi bi-arrow-left fs-21 p-r-5 line-height-1"></i>
                    <span className="fs-13 font-weight-semi">
                      {trans.fetchLabelKeyTranslation("BackBtnText","Back to Grid")}
                    </span>
                  </Link>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <CallListing
        isBasic={!docked}
        loading={loading}
        error={error}
        callData={calls?.calls}
        handleCallFlagUpdate={handleCallFlagUpdate}
        enableServerSidePagination={true}
        searchTerm={""}
        pageDetail={calls?.page}
        handlePaginationChange={handlePaginationChange}
        handleSortingChange={handleSortChange}
      />
    </React.Fragment>
  );
};

export default InsightCallsGrid;
