import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from "react";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import CustomMultiSelect from "../../../components/custom/form/CustomMultiSelect";
import CustomStandardUserGridInfo from "../../../components/custom/grid/CustomStandardUserGridInfo";
import {
  InsertScorecard,
  Scorecard,
  UpdateScorecard,
} from "../../../types/scorecard";
import {
  ScorecardAllocation,
  ScorecardAllocationInstance,
} from "../../../types/scorecard/ScorecardAllocation";
import { TinyObj } from "../../../types/TinyObj";
import { TinyUser } from "../../../types/user";
import { Group } from "../../../types/user-groups/Group";
import { axiosWithAuth } from "../../../utils/customAxios";
import CustomGridGroupInfo from "../../../components/custom/grid/CustomGridGroupInfo";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import userGroupsService from "../../../services/usergroups.service";
import useMasterData from "../../../hooks/useMasterData";
import useSwal from "../../../hooks/useSwal";
import { User } from "../../../types/user";
import useTranslation from "../../../hooks/useTranslation";

interface IScorecardDialogProps {
  toggleDialog: () => void;
  scorecardWithAllocation?: ScorecardWithAllocation;
  upsertScorecard: (
    isUpdated: boolean,
    scoreAllocation: ScorecardWithAllocation
  ) => void;
}

interface ScorecardWithAllocation {
  scorecard: Scorecard;
  allocation: ScorecardAllocation;
}

const UpsertScorecardDialog: React.FC<IScorecardDialogProps> = ({
  toggleDialog,
  scorecardWithAllocation,
  upsertScorecard,
}) => {
  const masterData = useMasterData();
  const trans = useTranslation("UpsertScorecardDialog");
  const localeCtx = useLocale();
  const swal = useSwal();
  const [loading, setLoading] = useState<boolean>(false);
  const [allGroups, setAllGroups] = useState<Group[] | undefined>(undefined);

  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);
  const [licenseTracker, setLicenseTracker] = useState<boolean>(false);
  const [essentialUser, setEssentialUser] = useState<string[]>();
  const [licenseTrackerGP, setLicenseTrackerGP] = useState<boolean>(false);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertScorecardDialog"
      ]
    ) {
      trans.fetchTranslations("UpsertScorecardDialog");
    }
  }, [localeCtx?.selectedLocale]);

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: User = {
      id: itemProps.dataItem.id,
      loginUserId: itemProps.dataItem.loginUserId,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
      isMicrosoftLogin: itemProps.dataItem.isMicrosoftLogin,
      loginEnabled: itemProps.dataItem.loginEnabled,
      devices: itemProps.dataItem.devices,
      recordingNetwork: itemProps.dataItem.recordingNetwork,
      topics: itemProps.dataItem.topics,
      callResults: itemProps.dataItem.callResults,
      reasonCodes: itemProps.dataItem.reasonCodes,
      moments: itemProps.dataItem.moments,
      associatedGroups: itemProps.dataItem.associatedGroups,
      scorecards: itemProps.dataItem.scorecards,
      licensePackage: itemProps.dataItem.licensePackage,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomStandardUserGridInfo
          userInfo={userInfo}
          selected={itemProps.selected}
        />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const itemRenderGroup = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const checkedGroup = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedGroup}>
        <CustomGridGroupInfo group={itemProps.dataItem}></CustomGridGroupInfo>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const nameValidator = (value: string) => {
    if (!value) {
      return trans.fetchLabelKeyTranslation(
          "SCNameValidationText1",
          "Scorecard Name cannot be empty"
        );
    }
    if (value.length > 100) {
      return trans.fetchLabelKeyTranslation(
          "SCNameValidationText2",
          "Scorecard name length cannot be more than 100!"
        );
    }

    return "";
  };

  const [error, setError] = useState<string | undefined>();

  const handleAdd = (
    scorecardVal: InsertScorecard,
    scoreAllocation: ScorecardAllocation
  ) => {
    axiosWithAuth
      .post(`/scorecard`, scorecardVal)
      .then(async (response) => {
        const sc: Scorecard = response.data;
        const scorecardName: TinyObj = {
          id: sc.id,
          name: sc.scorecardName,
        };
        axiosWithAuth
          .post(`scorecard/${sc.id}/allocation`, scoreAllocation)
          .then((response) => {
            swal.fire({
              icon: "success",
              title: `${trans.fetchLabelKeyTranslation(
                    "SwtSCCreatedSuccessTitle",
                    "Scorecard Created"
                  )
                }`,
              confirmButtonText: `${trans.fetchLabelKeyTranslation("OKText", "OK")
                }`,
            });
            const scorecardWithAllocation: ScorecardWithAllocation = {
              scorecard: sc,
              allocation: scoreAllocation,
            };
            upsertScorecard(false, scorecardWithAllocation);
          })
          .catch((err) => {
            if (err instanceof Error) {
              swal.fire({
                icon: "error",
                title: `${trans.fetchLabelKeyTranslation(
                      "SwtSCCreatedFailedTitle",
                      "Error"
                    )
                  }`,
                text: `${trans.fetchLabelKeyTranslation(
                      "SwtSCCreatedFailedText",
                      "Something went wrong."
                    )
                  }`,
                confirmButtonText: `${trans.fetchLabelKeyTranslation("OKText", "OK")
                  }`,
              });
            }
          });
        toggleDialog();
      })
      .catch((err) => {
        if (err.response.data.error === "Name already exists.") {
          setError(
            `${trans.fetchLabelKeyTranslation(
                "InvalidNameTitle",
                "Scorecard with same name already exists."
              )
            }`
          );
        } else if (err.response.data.error === "Name cannot be empty") {
          setError(
            `${trans.fetchLabelKeyTranslation(
                "SCEmptyNameTitle",
                "Scorecard Name cannot be empty."
              )
            }`
          );
        } else {
          setError(
            `${trans.fetchLabelKeyTranslation(
                "SwtSCCreatedFailedText",
                "Something went wrong."
              )
            }`
          );
        }
      });
  };

  const handleUpdate = (
    scorecardVal: UpdateScorecard,
    scoreAllocation: ScorecardAllocation,
    id: number
  ) => {
    let isAllocationUpdationOnly = false;
    if (
      scorecardVal.scorecardName ===
      scorecardWithAllocation?.scorecard.scorecardName &&
      scorecardVal.description === scorecardWithAllocation.scorecard.description
    ) {
      isAllocationUpdationOnly = true;
    }
    if (isAllocationUpdationOnly) {
      axiosWithAuth
        .post(
          `scorecard/${scorecardWithAllocation?.scorecard.id}/allocation`,
          scoreAllocation
        )
        .then((response) => {
          swal.fire({
            icon: "success",
            title: `${trans.fetchLabelKeyTranslation(
                  "SwtSCCreatedUpdatedTitle",
                  "Scorecard Updated"
                )
              }`,
            confirmButtonText: `${trans.fetchLabelKeyTranslation("OKText", "OK")
              }`,
          });
          if (scorecardWithAllocation?.scorecard) {
            const scorecardWithAllocationupdated: ScorecardWithAllocation = {
              scorecard: scorecardWithAllocation?.scorecard,
              allocation: scoreAllocation,
            };
            upsertScorecard(true, scorecardWithAllocationupdated);
          }
          toggleDialog();
        });
    } else {
      axiosWithAuth
        .put(`/scorecard/${id}`, scorecardVal)
        .then(async (response) => {
          const sc: Scorecard = response.data;
          const scorecardName: TinyObj = {
            id: sc.id,
            name: sc.scorecardName,
          };
          axiosWithAuth
            .post(`scorecard/${sc.id}/allocation`, scoreAllocation)
            .then((response) => {
              swal.fire({
                icon: "success",
                title: `${trans.fetchLabelKeyTranslation(
                      "SwtSCCreatedUpdatedTitle",
                      "Scorecard Updated"
                    )
                  }`,
                confirmButtonText: `${trans.fetchLabelKeyTranslation("OKText", "OK")
                  }`,
              });
              const scorecardWithAllocation: ScorecardWithAllocation = {
                scorecard: sc,
                allocation: scoreAllocation,
              };
              upsertScorecard(true, scorecardWithAllocation);
            })
            .catch((err) => {
              swal.fire({
                icon: "error",
                title: `${trans.fetchLabelKeyTranslation(
                      "SwtSCCreatedFailedTitle",
                      "SwtSCCreatedUpdatedText"
                    )
                  }`,
                text: `${trans.fetchLabelKeyTranslation(
                      "SwtSCCreatedFailedText",
                      "Something went wrong."
                    )
                  }`,
                confirmButtonText: `${trans.fetchLabelKeyTranslation("OKText", "OK")
                  }`,
              });
            });
          toggleDialog();
        })
        .catch((err) => {
          if (err.response.status == 405) {
            toggleDialog();
            swal
              .fire({
                icon: "info",
                title: `${trans.fetchLabelKeyTranslation(
                      "SwtAltUpdateFailedTitle",
                      "Scorecard cannot be Updated"
                    )
                  }`,
                text: `${trans.fetchLabelKeyTranslation(
                      "SwtSCDeleteConfirmText",
                      "You can archive the Scorecard"
                    )
                  }`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `${trans.fetchLabelKeyTranslation(
                      "SwtAltArchiveIfBtnText",
                      "Yes, Archive it!"
                    )
                  }`,
                cancelButtonText: `${trans.fetchLabelKeyTranslation("SwtAltCancelBtnText", "Cancel")
                  }`,
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  const updatedScorecard: Scorecard | undefined =
                    scorecardWithAllocation?.scorecard;
                  if (updatedScorecard != undefined) {
                    updatedScorecard.isArchived = true;
                  }
                  await axiosWithAuth
                    .put(
                      `/scorecard/${scorecardWithAllocation?.scorecard.id}`,
                      updatedScorecard
                    )
                    .then((response) => {
                      swal.fire({
                        icon: "success",
                        title: `${trans.fetchLabelKeyTranslation(
                              "SwtAltSCArchivedSuccessTitle",
                              "Scorecard Archived"
                            )
                          }`,
                        text: `${trans.fetchLabelKeyTranslation(
                              "SwtAltSCArchivedSuccessText",
                              "Scorecard has been archived successfully"
                            )
                          }`,
                      });
                      const newSCwithAllocation: ScorecardWithAllocation = {
                        scorecard: response.data,
                        allocation: scorecardWithAllocation?.allocation
                          ? scorecardWithAllocation?.allocation
                          : scoreAllocation,
                      };
                      upsertScorecard(true, newSCwithAllocation);
                    });
                }
              });
          } else {
            if (err.response.data.error === "Name already exists.") {
              setError(
                `${trans.fetchLabelKeyTranslation(
                    "InvalidNameTitle",
                    "Scorecard with same name already exists."
                  )
                }`
              );
            } else if (err.response.data.error === "Name cannot be empty") {
              setError(
                `${trans.fetchLabelKeyTranslation(
                    "SCEmptyNameTitle",
                    "Scorecard Name cannot be empty."
                  )
                }`
              );
            } else {
              setError(
                `${trans.fetchLabelKeyTranslation(
                    "SwtSCCreatedFailedText",
                    "Something went wrong."
                  )
                }`
              );
            }
          }
        });
    }
  };

  const getAllUserGroups = async () => {
    const groups = await userGroupsService.getUserGroups();
    setAllGroups(groups);
  };

  const formRef = useRef<any>();

  const submitHandler = () => {
    setError(undefined);
    const form = formRef.current as Form;
    const scorecardCreateVal: InsertScorecard = {
      scorecardName: form.values.scorecardName,
      description: form.values.description,
    };
    const scorecardUpdateVal: UpdateScorecard = {
      scorecardName: form.values.scorecardName,
      description: form.values.description,
    };

    const tinySc: TinyObj = {
      id: scorecardWithAllocation?.scorecard.id
        ? scorecardWithAllocation?.scorecard.id
        : 0,
      name: form.values.scorecardName,
    };

    const users: ScorecardAllocationInstance[] = form.values.users.map(
      (user: TinyUser) => {
        const allocInstance: ScorecardAllocationInstance = {
          user: user,
        };
        return allocInstance;
      }
    );

    const groups: ScorecardAllocationInstance[] = form.values.groups.map(
      (group: TinyObj) => {
        const allocInstance: ScorecardAllocationInstance = {
          group: group,
        };
        return allocInstance;
      }
    );

    const scoreAllocation: ScorecardAllocation = {
      ScorecardName: tinySc,
      users: users,
      groups: groups,
    };
    !scorecardWithAllocation
      ? handleAdd(scorecardCreateVal, scoreAllocation)
      : handleUpdate(
        scorecardUpdateVal,
        scoreAllocation,
        scorecardWithAllocation.scorecard.id
      );
  };

  const checkUser = (UserValue: TinyUser[]) => {
    let tracker: User[] = [];
    let essTrack: string[] = [];

    for (var j = 0; j < UserValue.length; j++) {
      tracker = tracker.concat(
        masterData?.standardUsers.filter(
          (m) => m.email == UserValue[j].email && m.isArchived == false
        ) ?? []
      );
    }

    tracker?.map((u) => {
      if (!u.licensePackage?.baseLicense || u.licensePackage?.baseLicense.name == "Essential") {
        essTrack.push(u.email);
      }
    });

    if (essTrack.length > 0) setLicenseTracker(true);
    else setLicenseTracker(false);

    setEssentialUser(essTrack);

    compareState();
  };

  const checkGroup = (GroupValue: Group[]) => {
    let gpTracker: User[] = [];
    let essTrackGP: string[] = [];

    for (var i = 0; i < GroupValue.length; i++) {
      console.log(GroupValue[i].groupMembers);
      for (var j = 0; j < GroupValue[i].groupMembers?.length; j++) {
        gpTracker = gpTracker.concat(
          masterData?.standardUsers.filter(
            (m) => m.email == GroupValue[i].groupMembers[j].member.email
          ) ?? []
        );
      }
    }

    gpTracker.map((g) => {
      if (!g.licensePackage || g.licensePackage.baseLicense.name == "Essential") {
        essTrackGP.push(g.email);
      }
    });

    if (essTrackGP.length > 0) setLicenseTrackerGP(true);
    else setLicenseTrackerGP(false);

    compareState();
  };

  const compareState = () => {
    const form = formRef.current as Form;
    if (
      compareArray(
        scorecardWithAllocation?.allocation.groups
          ?.map((g) => {
            return g.group;
          })
          .slice() ?? [],
        form.values.groups
      ) &&
      compareArray(
        scorecardWithAllocation?.allocation.users
          ?.map((u) => {
            return u.user;
          })
          .slice() ?? [],
        form.values.users
      ) &&
      form.values.scorecardName ==
      scorecardWithAllocation?.scorecard.scorecardName &&
      form.values.description == scorecardWithAllocation?.scorecard.description
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  const compareArray = (array1: any[], array2: any[]): boolean => {
    array1.sort(function (a, b) {
      if (a.id > b.id) return 1;
      else return -1;
    });

    array2.sort(function (a, b) {
      if (a.id > b.id) return 1;
      else return -1;
    });

    if (array1.length != array2.length) return false;
    else {
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].id != array2[i].id) return false;
      }
      return true;
    }
  };

  useEffect(() => {
    getAllUserGroups();
  }, []);

  return (
    <Form
      ref={formRef}
      initialValues={{
        scorecardName: scorecardWithAllocation?.scorecard.scorecardName ?? "",
        description: scorecardWithAllocation?.scorecard.description ?? "",
        users:
          scorecardWithAllocation?.allocation.users?.map((u) => {
            return u.user;
          }) ?? [],
        groups:
          scorecardWithAllocation?.allocation.groups?.map((g) => {
            return g.group;
          }) ?? [],
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              scorecardWithAllocation
                ? `${trans.fetchLabelKeyTranslation(
                    "UpsertSCTitleEdit",
                    "Edit Scorecard"
                  )
                }`
                : `${trans.fetchLabelKeyTranslation(
                    "UpsertSCTitleAdd",
                    "Add Scorecard"
                  )
                }`
            }
            onClose={toggleDialog}
          >
            <div className="formAdd" style={{ marginBottom: "0px", minWidth: "250px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {trans.fetchLabelKeyTranslation(
                        "UpsertSCName",
                        "Scorecard Name"
                      )
                  }
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="scorecardName"
                      name="scorecardName"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("scorecardName")}
                      placeholder={trans.fetchLabelKeyTranslation(
                            "UpsertSCNamePlaceHolderText",
                            "Enter Scorecard name..."
                          )
                        }
                      component={CustomInput}
                      validator={nameValidator}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {trans.fetchLabelKeyTranslation("Description", "Description")
                  }
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id={"description"}
                      name={"description"}
                      style={{
                        minWidth: "250px",
                        height: "auto",
                        minHeight: "70px",
                      }}
                      max={1000}
                      autoSize={true}
                      rows={3}
                      showTextLimitHint={false}
                      placeholder={trans.fetchLabelKeyTranslation(
                            "UpsertSCDescriptionPlaceHolderText",
                            "Enter Scorecard description..."
                          )
                        }
                      value={formRenderProps.valueGetter("description")}
                      component={CustomFormTextArea}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxLabel fs-14">
                  {trans.fetchLabelKeyTranslation(
                        "UpsertSCAssignUsers",
                        "Assign Users"
                      )
                  }
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="users"
                      name="users"
                      data={masterData?.users?.filter(
                        (u) => u.isArchived === false
                      )}
                      fillMode="solid"
                      component={CustomMultiSelect}
                      itemRender={itemRenderUser}
                      textField="email"
                      value={formRenderProps.valueGetter("users")}
                      placeholder={trans.fetchLabelKeyTranslation(
                            "UpsertSCAssignUsersPlaceHolderText",
                            "Select Users"
                          )
                        }
                      dataItemKey="id"
                      filtering={true}
                      sorting={true}
                      sortField={"email"}
                      onChange={(e) => checkUser(e.value)}
                    />
                  </div>
                </div>
              </div>
              {licenseTracker && (
                <div>
                  <div className="fs-10 text-danger mb-2">
                    {essentialUser?.map((e) => { return `${e} ` + " " })
                      + " " +
                      (trans.fetchLabelKeyTranslation(
                          "HasEssentialLicense",
                          "requires an Advanced or Ultimate license to enable scorecard evaluation."
                        ))}
                  </div>
                </div>
              )}
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxLabel fs-14">
                  {`${trans.fetchLabelKeyTranslation(
                        "UpsertSCAssignGroups",
                        "Assign User Groups"
                      )
                    }`}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="groups"
                      name="groups"
                      data={allGroups}
                      component={CustomMultiSelect}
                      itemRender={itemRenderGroup}
                      textField="name"
                      value={formRenderProps.valueGetter("groups")}
                      placeholder={`${trans.fetchLabelKeyTranslation(
                            "UpsertSCAssignGroupsPlaceHolderText",
                            "Select User Groups"
                          )
                        }`}
                      dataItemKey="id"
                      fillMode="solid"
                      filtering={true}
                      sorting={true}
                      sortField={"name"}
                      onChange={(e) => checkGroup(e.value)}
                    />
                  </div>
                </div>
              </div>
              {licenseTrackerGP && (
                <div>
                  <div className="fs-10 text-danger mb-2">
                    {trans.fetchLabelKeyTranslation(
                        "HasEssentialLicenseGP",
                        "Some Users in this group require an Advanced or Ultimate license to enable scorecard evaluation."
                      )}
                  </div>
                </div>
              )}
            </div>
            <DialogActionsBar>
              <Button onClick={toggleDialog}>
                {`${trans.fetchLabelKeyTranslation(
                      "UpsertCancelButtonText",
                      "Cancel"
                    )
                  }`}
              </Button>
              <Button
                className={` ${"k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white" +
                  ((
                    !formRenderProps.valid || (buttonStateTracker &&
                      scorecardWithAllocation)
                      ? true
                      : false
                  )
                    ? "disabledBtn"
                    : "")
                  }`}
                onClick={submitHandler}
                disabled={
                  !formRenderProps.valid || (buttonStateTracker &&
                    scorecardWithAllocation)
                    ? true
                    : false
                }
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  `${scorecardWithAllocation
                    ? `${trans.fetchLabelKeyTranslation("UpdateButton", "Update")
                    }`
                    : `${trans.fetchLabelKeyTranslation("AddButton", "Add")
                    }`
                  }`
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertScorecardDialog;
