import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxCloseEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";
import { ShortScorcardType } from "../../pages/call-details/RecordingScorecardTab";
import scorecardService from "../../services/scorecard.service";
import { Scorecard } from "../../types/scorecard";
import { sortDataFunc } from "../../utils/scorecardSort";
import { EntityLocalizationGroupEnum } from "../../enums/accessPermissionEnum";
import useMasterData from "../../hooks/useMasterData";

interface ScorecardSelectDialogProps {
  selectedScorecard: ShortScorcardType;
  onCloseDialog: () => void;
  onScorecardSelect: (scorecard: ShortScorcardType) => void;
  fetchKeyTranslation: (key: string, defaultVal: string) => string;
  allocatedScorecards?: Scorecard[];
  baseLicenseId: number;
}

const ScorecardSelectDialog: React.FC<ScorecardSelectDialogProps> = ({
  selectedScorecard,
  onCloseDialog,
  onScorecardSelect,
  fetchKeyTranslation,
  allocatedScorecards,
  baseLicenseId,
}) => {
  const master = useMasterData();
  const scorecardComboxBoxRef = useRef<any>();
  const [value, setValue] = useState<ShortScorcardType>(selectedScorecard);

  const [scorecards, setScorecards] = useState<
    ShortScorcardType[] | undefined
  >();
  const [filterTerm, setFilterTerm] = useState("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const sendFetchScorecardNames = async () => {
      try {
        setLoading(true);
        const data = await scorecardService.fetchAllScorecards();
        const shortNamesSuggested: ShortScorcardType[] = [],
          shortNamesOthers: ShortScorcardType[] = [];
        const filteredScorecards: Scorecard[] = data.filter((sc) => {
          if(baseLicenseId === 1){
            return sc.isSystemDefined === true && sc.isArchived === false;
          }
          else return sc.isArchived === false;
        });
        filteredScorecards.forEach((s: Scorecard) => {
          if (
            allocatedScorecards?.find(
              (allocatedSC: Scorecard) => allocatedSC.id === s.id
            )
          ) {
            shortNamesSuggested.push({
              id: s.id,
              text: s.scorecardName,
              isArchived: false,
              position: "Suggested",
            });
          }
        });
        const sortedShortNamesSuggested: ShortScorcardType[] =
          sortDataFunc(shortNamesSuggested);
        filteredScorecards.forEach((s: Scorecard) => {
          if (
            !allocatedScorecards?.find(
              (allocatedSC: Scorecard) => allocatedSC.id === s.id
            )
          ) {
            shortNamesOthers.push({
              id: s.id,
              text: s.scorecardName,
              isArchived: false,
              position: "Others",
            });
          }
        });
        const sortedShortNamesOthers: ShortScorcardType[] =
          sortDataFunc(shortNamesOthers);
        setScorecards(sortedShortNamesSuggested.concat(sortedShortNamesOthers));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    if (allocatedScorecards) {
      sendFetchScorecardNames();
    }
  }, [allocatedScorecards]);

  const onChangeHandler = (e: ComboBoxChangeEvent) => {
    setValue(e.value);
  };

  const onSubmitHandler = () => {
    const scorecard = scorecardComboxBoxRef?.current.value;
    onScorecardSelect(scorecard);
  };

  const valueRender = (element: any) => {
    if (!value) {
      return element;
    }
    return (
      <span className="d-flex align-items-center m-l-10" style={{width:"100%"}}>
        {fetchKeyTranslation(
          master?.getEntityNameKeyfromConfigMap(
            EntityLocalizationGroupEnum.Scorecard,
            element.props.value ?? ""
          ) ?? "",
          element.props.value
        )}
      </span>
    )
  };

  const itemRender = (li: any, itemProps: any) => {
    const itemChildren =
      itemProps.dataItem.position === "Suggested" ? (
        <span>
          <b>{itemProps.dataItem.text}</b>{" "}
          <span style={{ fontSize: "12px", color: "grey" }}>(suggested)</span>
        </span>
      ) : (
        <span>
           <b>{}</b>
          <b>
            {fetchKeyTranslation(
                  master?.getEntityNameKeyfromConfigMap(
                    EntityLocalizationGroupEnum.Scorecard,
                    itemProps.dataItem.text ?? ""
                  ) ?? "",
                  itemProps.dataItem.text
                )}
          </b>
        </span>
      );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const filterScorecards = (event: ComboBoxFilterChangeEvent) => {
    setFilterTerm(event.filter.value.toLowerCase());
  };

  return (
    <Dialog
      title={fetchKeyTranslation("TextScorecards", "Scorecards")}
      onClose={onCloseDialog}
    >
      <p className="m-t-30 m-b-40" style={{ textAlign: "center" }}>
        <ComboBox
          filterable={true}
          onFilterChange={filterScorecards}
          ref={scorecardComboxBoxRef}
          style={{
            width: "350px",
            minHeight: "36px",
          }}
          textField="text"
          dataItemKey="id"
          data={
            scorecards &&
            scorecards?.filter((sc: ShortScorcardType) =>
              sc.text.toLowerCase().includes(filterTerm)
            )
          }
          value={value}
          valueRender={valueRender}
          loading={loading}
          onChange={onChangeHandler}
          defaultValue={{
            id: -1,
            text: fetchKeyTranslation("PlaceholderSelect", "Select"),
          }}
          allowCustom={false}
          itemRender={itemRender}
          onClose={(e: ComboBoxCloseEvent) => {
            if (e.nativeEvent.type === "keydown" && !e.target.value) {
              setFilterTerm("");
            }
          }}
        />
      </p>
      <DialogActionsBar>
        <Button className="btn" onClick={onCloseDialog}>
          {fetchKeyTranslation("CancelText", "Cancel")}
        </Button>
        <Button className="btn bg-primary text-white" onClick={onSubmitHandler}>
          {fetchKeyTranslation("PlaceholderSelect", "Select")}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ScorecardSelectDialog;
