import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { Buffer } from 'buffer';
import React, { useEffect, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { Checkbox, CheckboxChangeEvent, Input, RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import { DeviceAllocation } from "../../types/device/deviceAllocation";
import { MsTeamsRecordingMode } from "../../types/recording-mode/RecordingMode";
import { Customer, CustomerLicensePackageCount } from "../../types/customer";
import { TinyUser } from "../../types/user";
import CustomUserGridInfo from "../../components/custom/grid/CustomUserGridInfo";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CustomComboBox from "../../components/custom/form/CustomComboBox";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import useMasterData from "../../hooks/useMasterData";
import { Button } from "@progress/kendo-react-buttons";
import { MsTeamsUserPolicyDetail } from "../../types/localstorage-filters/MsTeamsUserPolicyDetail";
import useSessionStorage from "../../hooks/useSessionStorage";
import useAuth from "../../hooks/useAuth";
import { CustomerPlatformStatus } from "../../types/customer/CustomerPlatformStatus";
import { GraphUrlStateDetails } from "../../types/customer/GraphUrlStateDetails";
import { MsTeamsProvisioningActionEnum } from "../../enums/MsTeamsProvisioningActionEnum";
import { appUrl } from "../..";
import useLocale from "../../hooks/useLocale";

interface RecordedUserDialogBoxProps {
    devicesLoading:boolean;
    toggleUserDialog: () => void;
    msTeamsDevicesConstant:DeviceAllocation[];
    customerInfo:Customer | undefined;
    isPolicyAssignmentValid:boolean;
    platformStatus:CustomerPlatformStatus | null;
    authCodeUrlTemplate:string;
}

const RecordedUserDialogBox: React.FC<RecordedUserDialogBoxProps> = ({
    devicesLoading,
    toggleUserDialog,
    msTeamsDevicesConstant,
    customerInfo,
    isPolicyAssignmentValid,
    platformStatus,
    authCodeUrlTemplate
}) => {
    const EDIT_SECTION_CLASSNAME = "edit-section";
    const masterData=useMasterData();
    const auth=useAuth();
    const localeCtx=useLocale();
    const trans=useTranslation("MsTeamsRecordedUserDialogBox");
    const [msTeamsRecordingPolicyUsers, setMsTeamsRecordingPolicyUsers] = useSessionStorage<MsTeamsUserPolicyDetail[] | undefined>(
        "MsTeamsRecordingPolicyUsers",
        undefined
      );
    const [showEditSection, setShowEditSection] = useState<string>("");
    const [assignedDevices,setAssignedDevices]=useState<DeviceAllocation[]>([]);
    const [unassignedDevices,setUnassignedDevices]=useState<DeviceAllocation[]>([]);
    const [assignedDeviceSearch, setAssignedDeviceSearch] = useState<string>("");
    const [unassignedDeviceSearch, setUnassignedDeviceSearch] = useState<string>("");
    const [msTeamsRecordingModeSelected,setMsTeamsRecordingModeSelected]=useState<MsTeamsRecordingMode|undefined>();
    const [licensePackageSelected,setLicensePackageSelected]=useState<CustomerLicensePackageCount|undefined>();
    const [editMsTeamsRecordingModeSelected,setEditMsTeamsRecordingModeSelected]=useState<MsTeamsRecordingMode|undefined>();
    const [editLicensePackageSelected,setEditLicensePackageSelected]=useState<CustomerLicensePackageCount|undefined>();
    const [msTeamsDevices, setMsTeamsDevices] = useState<DeviceAllocation[]>(msTeamsDevicesConstant);
    const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

    useEffect(() => {
      if (!localeCtx?.selectedLocale?.current.componentTranslations["MsTeamsRecordedUserDialogBox"]) {
        trans.fetchTranslations("MsTeamsRecordedUserDialogBox")
      }
    }, [localeCtx?.selectedLocale]);

    useEffect(()=>{
      setButtonStateTracker(true);
      for(let i=0;i<msTeamsDevicesConstant.length;i++){
        const findCorrespondingMsTeamsDevice=msTeamsDevices.find((_d:DeviceAllocation)=>_d.device.deviceIdentifier===msTeamsDevicesConstant[i].device.deviceIdentifier);
        if(msTeamsDevicesConstant[i].device.deviceDetails.isRecordingPolicyAssigned!==findCorrespondingMsTeamsDevice?.device.deviceDetails.isRecordingPolicyAssigned ||
          msTeamsDevicesConstant[i].licensePackage?.name!==findCorrespondingMsTeamsDevice?.licensePackage?.name ||
          msTeamsDevicesConstant[i].teamsRecordingMode?.modeName !== findCorrespondingMsTeamsDevice?.teamsRecordingMode?.modeName
        ){
          setButtonStateTracker(false);
          return;
        }
      }
    },[msTeamsDevices])


    const handleUnassignedDeviceSearchChange = (event: any) => {
      setUnassignedDeviceSearch(event.value);
    };

    const handleAssignedDeviceSearchChange = (event: any) => {
      setAssignedDeviceSearch(event.value);
    };

    const handleDeviceSelectionAll = (e:CheckboxChangeEvent) => {
      if(e.value){
        let _devices:DeviceAllocation[]=[];
        msTeamsDevices.forEach((device:DeviceAllocation)=>{
          if(!device.device.deviceDetails.isRecordingPolicyAssigned)_devices.push(device);
        })
        setUnassignedDevices(_devices);
      }else{
        setUnassignedDevices([]);
      }
    }

    const handleDeviceSelection = (device: DeviceAllocation,e:CheckboxChangeEvent) => {
      if(e.value){
        setUnassignedDevices([...unassignedDevices,device])
        setAssignedDevices([]);
      }else{
        if(unassignedDevices.length>1)setAssignedDevices([]);
        setUnassignedDevices(unassignedDevices.filter((unassignedDevice:DeviceAllocation)=>unassignedDevice.device.deviceIdentifier!==device.device.deviceIdentifier))
      }
    }

    const handleRemoveAll = (e:CheckboxChangeEvent) => {
      if(e.value){
        let _devices:DeviceAllocation[]=[];
        msTeamsDevices.forEach((device:DeviceAllocation)=>{
          if(device.device.deviceDetails.isRecordingPolicyAssigned)_devices.push(device);
        })
        setAssignedDevices(_devices);
      }else{
        setAssignedDevices([]);
      }
    }

    const handleDeviceRemove = (device: DeviceAllocation,e:CheckboxChangeEvent) => {
      if(e.value){
        setAssignedDevices([...assignedDevices,device])
        setUnassignedDevices([]);
      }else{
        if(assignedDevices.length>1)setUnassignedDevices([]);
        setAssignedDevices(assignedDevices.filter((assignedDevice:DeviceAllocation)=>assignedDevice.device.deviceIdentifier!==device.device.deviceIdentifier))
      }
    }

    const handleEditSection = (device:DeviceAllocation) => {
      if(showEditSection===device.device.deviceIdentifier){
        setShowEditSection("");
        setEditMsTeamsRecordingModeSelected(undefined);
        setEditLicensePackageSelected(undefined)
      }else{
        const updateLicensePackage:any={
          id:device.licensePackage?.id,
          name: device.licensePackage?.name,
          baseLicense: device.licensePackage?.baseLicense,
          storageDuration: device.licensePackage?.storageDuration
        }
        setShowEditSection(device.device.deviceIdentifier);
        setEditMsTeamsRecordingModeSelected(device.teamsRecordingMode);
        setEditLicensePackageSelected(updateLicensePackage)
      }
    };

    const assignDeviceHandler=()=>{
      setMsTeamsDevices(msTeamsDevices.map((_device:DeviceAllocation)=>{
        const findDevice=unassignedDevices.find((d:DeviceAllocation)=>d.device.deviceIdentifier===_device.device.deviceIdentifier);
        if(findDevice){
          const updateLicensePackage:any={
            id:licensePackageSelected?.id,
            name: licensePackageSelected?.name,
            baseLicense: licensePackageSelected?.license,
            storageDuration: licensePackageSelected?.storageDuration
          }
          _device.device.deviceDetails.isRecordingPolicyAssigned=true;
          _device.licensePackage=updateLicensePackage;
          _device.teamsRecordingMode=msTeamsRecordingModeSelected
        }
        return _device
      }))
      setUnassignedDevices([]);
      setLicensePackageSelected(undefined);
      setMsTeamsRecordingModeSelected(undefined)
    }

    const unassignDeviceHandler=()=>{
      setMsTeamsDevices(msTeamsDevices.map((_device:DeviceAllocation)=>{
        const findDevice=assignedDevices.find((d:DeviceAllocation)=>d.device.deviceIdentifier===_device.device.deviceIdentifier);
        if(findDevice){
          _device.device.deviceDetails.isRecordingPolicyAssigned=false;
          _device.licensePackage=undefined;
        }
        return _device
      }))
      setAssignedDevices([])
    }

    const editMsTeamsRecordingModeChangeHandler=(mode:MsTeamsRecordingMode,device:DeviceAllocation)=>{
      setMsTeamsDevices(msTeamsDevices.map((_device:DeviceAllocation)=>{
        if(_device.device.deviceIdentifier===device.device.deviceIdentifier){
          _device.teamsRecordingMode=mode;
        }
        return _device
      }))
      setEditMsTeamsRecordingModeSelected(mode)
    }

    const editLicensePackageSelectedChangeHandler=(license:CustomerLicensePackageCount,device:DeviceAllocation)=>{
      setMsTeamsDevices(msTeamsDevices.map((_device:DeviceAllocation)=>{
        if(_device.device.deviceIdentifier===device.device.deviceIdentifier){
          const updateLicensePackage:any={
            id:license?.id,
            name: license.name,
            baseLicense: license.license,
            storageDuration: license.storageDuration
          }
          _device.licensePackage=updateLicensePackage;
        }
        return _device
      }))
      setEditLicensePackageSelected(license)
    }

    const searchDevice = (deviceDetails: DeviceAllocation, searchTerm: string) => {
      var searchText = searchTerm.trim().toLocaleLowerCase();
      if (
        deviceDetails.device.deviceDetails.msTeamsEmail?.toLocaleLowerCase().includes(searchText) ||
        deviceDetails.device.deviceDetails.msTeamsGivenName?.toLocaleLowerCase().includes(searchText)
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getDefaultLicenseName = (licenseInfo: CustomerLicensePackageCount[]) => {
      let _licenseInfo = licenseInfo.filter(l => l.purchased > 0).sort((l1, l2) => {
        let l1Storage: number = 0;
        let l2Storage: number = 0;
        switch(l1.storageDuration.durationType.toLocaleLowerCase()){
          case "day":{
            l1Storage = l1.storageDuration.duration;
            break;
          }
          case "month": {
            l1Storage = l1.storageDuration.duration * 30;
            break;
          }
          case "year": {
            l1Storage = l1.storageDuration.duration * 365;
            break;
          }
        }
        switch(l2.storageDuration.durationType.toLocaleLowerCase()){
          case "day":{
            l2Storage = l2.storageDuration.duration;
            break;
          }
          case "month": {
            l2Storage = l2.storageDuration.duration * 30;
            break;
          }
          case "year": {
            l2Storage = l2.storageDuration.duration * 365;
            break;
          }
        }
        return l1.license.isAIEnabled === l2.license.isAIEnabled ? (l1Storage > l2Storage ? -1 : 1) : (l1.license.isAIEnabled === true ? -1 : 1);
      })
      return _licenseInfo[0];
    }

    const getMsTeamsUserPolicyDetail = () => {
      let _msTeamsUserPolicyDetail : MsTeamsUserPolicyDetail[] = [];
      if(customerInfo && customerInfo.licensePackages && customerInfo.licensePackages.length > 0){
        let _licenseInfo = [...customerInfo?.licensePackages];
        let _autoLearnLicense = _licenseInfo.find(l => l.isAutoLearnEnabled === true);
        let _users = msTeamsDevices.filter(d => d.device.deviceDetails.isRecordingPolicyAssigned);
        _users.forEach(u => {
          let _userDetail : MsTeamsUserPolicyDetail = {
            userEmail: u.device.deviceDetails.msTeamsEmail ?? "",
            licenseName: u.licensePackage?.name??"",
            policyType: u.teamsRecordingMode?.modeName??""
          };
          if(u.licensePackage){
            _userDetail.licenseName = u.licensePackage.name;
          }else{
            if(_autoLearnLicense){
              _userDetail.licenseName = _autoLearnLicense.name;
            }
            else{
              let _license = getDefaultLicenseName(_licenseInfo);
              _userDetail.licenseName = _license.name;
              let _licenseIndex = _licenseInfo.findIndex(l => l.name === _license.name);
              _licenseInfo[_licenseIndex].purchased = _license.purchased - 1;
            }
          }
          _msTeamsUserPolicyDetail.push(_userDetail);
        });
      }
      return _msTeamsUserPolicyDetail;
    }

    const updatePolicyAssignment = async () => {
      if(!isPolicyAssignmentValid){
        return;
      }
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId && platformStatus && platformStatus.customerOnboardingDetail.azureAppClientId !== undefined &&
        platformStatus.customerOnboardingDetail.tenantGUID !== undefined &&
        platformStatus.customerOnboardingDetail.msTeamsAccessCodeUrlScope !== undefined) {
        let _userDetail = getMsTeamsUserPolicyDetail();
        setMsTeamsRecordingPolicyUsers(_userDetail);
        let urlState: GraphUrlStateDetails = {
          customerId: customerId,
          platform: "MsTeams",
          msTeamsProvisioningAction: MsTeamsProvisioningActionEnum.AssignRecordingPolicyToUsers
        };
        let encodedUrlState = Buffer.from(JSON.stringify(urlState)).toString("base64");
        let _authCodeUrl = authCodeUrlTemplate;
        _authCodeUrl = _authCodeUrl.replace("{tenant_id}", encodeURIComponent(platformStatus.customerOnboardingDetail.tenantGUID ?? ""));
        _authCodeUrl = _authCodeUrl.replace("{client_id}", encodeURIComponent(platformStatus.customerOnboardingDetail.azureAppClientId));
        _authCodeUrl = _authCodeUrl.replace("{scope}", encodeURIComponent(platformStatus.customerOnboardingDetail.msTeamsAccessCodeUrlScope));
        _authCodeUrl = _authCodeUrl.replace("{redirect_uri}", encodeURIComponent(appUrl + `/customer-platform/admin-action`));
        _authCodeUrl = _authCodeUrl.replace("{state}", encodeURIComponent(encodedUrlState));
        if (_authCodeUrl !== null) {
          window.location.replace(_authCodeUrl);
        }
      }
    }

    return(
        <Dialog
            title={trans?.fetchLabelKeyTranslation("DialogTitle", "Select Users to be Recorded")}
            onClose={
                toggleUserDialog
            }
            >
            {devicesLoading ?
              <div className="col-md-12 d-flex justify-content-center align-items-center">
                <Loader type={"infinite-spinner"} />
              </div>
              :
              <>
              <div
                className="userListingData"
                style={{ maxWidth: "1100px"}}
              >
                <div className="row">
                  <div className="col-md-5 m-b-10 pr-0">
                        <div className="userListingDataCol">
                        <div className="userListingTitle fs-14 text-black-10 font-weight-bold p-b-4 d-flex align-items-center justify-content-between">
                            <span>{trans?.fetchLabelKeyTranslation("DialogUnassignedUsersTitle", "Unassigned Users")}</span>
                        </div>
                        <div className="UserLiSearch p-b-5">
                            <div className="formInput">
                            <Input
                                className="form-control"
                                style={{ height: "32px", background: "rgba(255,255,255,.6)" }}
                                placeholder={trans?.fetchLabelKeyTranslation("DialogSearchText", "Search")}
                                value={unassignedDeviceSearch}
                                onChange={handleUnassignedDeviceSearchChange}
                            />
                            </div>
                        </div>
                        <div
                            className="detailColBox userListGroup"
                            style={{
                            height:
                                "325px",
                            overflow:
                                "hidden auto",
                            }}
                        >
                            <Checkbox
                            checked={unassignedDevices.length===msTeamsDevices.filter((device:DeviceAllocation)=>!device.device.deviceDetails.isRecordingPolicyAssigned).length}
                            className="mt-2 d-block"
                            label={trans?.fetchLabelKeyTranslation("DialogSelectAllText", "Select All")}
                            onChange={(e:CheckboxChangeEvent)=>handleDeviceSelectionAll(e)}
                            disabled={msTeamsDevices.filter((device:DeviceAllocation)=>!device.device.deviceDetails.isRecordingPolicyAssigned).length===0 || assignedDevices.length>0}
                            />
                            <div className="UserRow w-100 float-left p-t-10 p-b-10">
                            {msTeamsDevices.filter((device) => !device.device.deviceDetails.isRecordingPolicyAssigned && searchDevice(device, unassignedDeviceSearch)).map(device => {
                                var _user: TinyUser = {
                                id: device.device.deviceDetails?.id ?? 0,
                                loginUserId: device.user?.loginUserId ?? 0,
                                firstName: device.device.deviceDetails.msTeamsGivenName ?? "",
                                lastName: device.device.deviceDetails.msTeamsSurname ?? "",
                                email: device.device.deviceDetails.msTeamsEmail ?? "",
                                phoneNo: "",
                                image: device.user?.image ?? "",
                                isArchived: device.user?.isArchived ?? false
                                };
                                return (
                                <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                                    <div className="tblUsr d-flex justify-content-between w-100">
                                    <Checkbox
                                        className="mr-2"
                                        onChange={(e:CheckboxChangeEvent)=>handleDeviceSelection(device,e)}
                                        checked={unassignedDevices.find((_device:DeviceAllocation)=>_device.device.deviceIdentifier===device.device.deviceIdentifier) ?true:false}
                                        disabled={assignedDevices.length>0}
                                    />
                                    <CustomUserGridInfo userInfo={_user} />
                                    </div>
                                </div>
                                )
                            })}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-2 m-b-10 d-flex flex-column align-items-center justify-content-center">
                        {unassignedDevices.length>0 && (
                        <>
                        <div className="row">
                        <div className="col-12">
                            <div className="labYesNo">
                            <label>{trans?.fetchLabelKeyTranslation("DialogRPTitle", "Recording policy")}</label>
                            <Tooltip
                                anchorElement={"target"}
                                position={"top"}
                                parentTitle={true}
                            >
                                <div className="d-flex flex-column">
                                {masterData?.data?.teamsRecordingMode.map((mode:MsTeamsRecordingMode)=>{
                                    return (
                                    <span className="d-flex fs-13 mb-2">
                                    <RadioButton
                                    name={'recordingModes'}
                                    value={msTeamsRecordingModeSelected}
                                    label={mode.modeName}
                                    onChange={(e:RadioButtonChangeEvent)=>setMsTeamsRecordingModeSelected(mode)}
                                    />
                                    <i className="bi bi-info-circle ml-1 tx-amber" title={mode.modeDescription}></i>
                                </span>
                                    )
                                })}
                                </div>
                            </Tooltip>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <label>{trans?.fetchLabelKeyTranslation("DialogLicencesTitle", "Licences")}</label>
                            <CustomComboBox
                                className="recordingpolicy-dropdown w-100 mt-0"
                                data={customerInfo?.licensePackages
                                ?.filter((l) => {
                                    return (
                                    l.isAutoLearnEnabled ||
                                    l.consumed < l.purchased
                                    );
                                })
                                .map((x) => {
                                    return masterData?.data?.licensePackages.find(y => y.id === x.id)
                                })}
                                textField="localizationValue"
                                value={licensePackageSelected}
                                onChange={(e:ComboBoxChangeEvent)=>setLicensePackageSelected(e.value)}
                            />
                        </div>
                        </div>
                        <div className="userActionBtn  pt-3">
                        <a className="cursor-pointer">
                            <span className={`fs-25 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center
                            ${!msTeamsRecordingModeSelected || !licensePackageSelected || unassignedDevices.length===0 ?"disabledIconBtn cursor-default":""}
                            `}
                            style={{
                                height: "40px",
                                width: "40px",
                            }}
                            title={trans?.fetchLabelKeyTranslation(
                                "AddButton",
                                "Add")}
                                onClick={assignDeviceHandler}
                            >
                            <i className="bi bi-arrow-right fs-20"></i>
                            </span>
                        </a>
                        </div>
                        </>
                        )}
                        {assignedDevices.length>0 && (
                            <div className="userActionBtn pt-3">
                            <a className="cursor-pointer">
                            <span className={`fs-25 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center
                            ${assignedDevices.length===0?"disabledIconBtn cursor-default":""}`}
                                style={{
                                height: "40px",
                                width: "40px",
                                }}
                                title={trans?.fetchLabelKeyTranslation(
                                "RemoveButton",
                                "Remove"
                            )}
                                onClick={unassignDeviceHandler}
                            >
                                <i className="bi bi-arrow-left fs-20"></i>
                            </span>
                            </a>
                        </div>
                        )}
                    </div>
                    <div className="col-md-5 m-b-10 pl-0">
                        <div className="userListingDataCol">
                        <div className="userListingTitle fs-14 text-black-10 font-weight-bold p-b-4 d-flex align-items-center justify-content-between">
                            <span>{trans?.fetchLabelKeyTranslation("DialogAssignedUsersTitle", "Assigned Users")}</span>
                        </div>
                        <div className="UserLiSearch p-b-5">
                            <div className="formInput">
                            <Input
                                className="form-control"
                                style={{ height: "32px", background: "rgba(255,255,255,.6)" }}
                                placeholder={trans?.fetchLabelKeyTranslation("DialogSearchText", "Search")}
                                value={assignedDeviceSearch}
                                onChange={handleAssignedDeviceSearchChange}
                            />
                            </div>
                        </div>
                        <div
                            className="detailColBox userListGroup"
                            style={{
                            height:
                                "325px",
                            overflow:
                                "hidden auto",
                            }}
                        >
                            <Checkbox
                            checked={assignedDevices.length===msTeamsDevices.filter((device:DeviceAllocation)=>device.device.deviceDetails.isRecordingPolicyAssigned).length}
                            className="mt-2 d-block"
                            label={trans?.fetchLabelKeyTranslation("DialogSelectAllText", "Select All")}
                            onChange={(e:CheckboxChangeEvent)=>handleRemoveAll(e)}
                            disabled={msTeamsDevices.filter((device:DeviceAllocation)=>device.device.deviceDetails.isRecordingPolicyAssigned).length===0 || unassignedDevices.length>0}
                            />
                            <div className="UserRow w-100 float-left p-t-10 p-b-10">
                            {msTeamsDevices.filter((device) => device.device.deviceDetails.isRecordingPolicyAssigned && searchDevice(device, assignedDeviceSearch)).map(device => {
                                var _user: TinyUser = {
                                id: device.device.deviceDetails?.id ?? 0,
                                loginUserId: device.user?.loginUserId ?? 0,
                                firstName: device.device.deviceDetails.msTeamsGivenName ?? "",
                                lastName: device.device.deviceDetails.msTeamsSurname ?? "",
                                email: device.device.deviceDetails.msTeamsEmail ?? "",
                                phoneNo: "",
                                image: device.user?.image ?? "",
                                isArchived: device.user?.isArchived ?? false
                                };
                                return (
                                <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                                    <div className="tblUsr d-flex justify-content-between w-100">
                                    <Checkbox
                                        className="mr-2"
                                        onChange={(e:CheckboxChangeEvent)=>handleDeviceRemove(device,e)}
                                        checked={assignedDevices.find((_device:DeviceAllocation)=>_device.device.deviceIdentifier===device.device.deviceIdentifier)?true:false}
                                        disabled={unassignedDevices.length>0}
                                    />
                                    <div className="d-flex flex-column w-100">
                                        <CustomUserGridInfo userInfo={_user} />
                                        <div className="d-flex small text-muted">
                                        <i className="bi bi-clipboard-check mr-1"></i> {device.teamsRecordingMode?.modeName}
                                        <span className="mx-1">|</span>
                                        <i className="bi bi-award mr-1"></i> {device.licensePackage?.name}
                                        </div>
                                    </div>
                                    <div className="userActionBtn p-r-10 d-flex">
                                        <a className="cursor-pointer mr-1"
                                        onClick={() => unassignedDevices.length>0?{}:handleEditSection(device)}
                                        >
                                        <span className="fs-25 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center"
                                            style={{
                                            height: "30px",
                                            width: "30px",
                                            }}
                                            title="Edit"
                                        >
                                            <i className={`bi bi-pencil fs-16 ${unassignedDevices.length>0?"disabledIconBtn cursor-default":""}`}></i>
                                        </span>
                                        </a>
                                    </div>
                                    </div>
                                    {showEditSection && showEditSection===device.device.deviceIdentifier && (
                                    <div className={EDIT_SECTION_CLASSNAME}>
                                        <div className="row p-2 mt-2 bg-white">
                                        <div className="col-12">
                                            <div className="labYesNo">
                                            <label>{trans?.fetchLabelKeyTranslation("DialogEditRPTitle", "Recording policy")}</label>
                                            <Tooltip
                                                anchorElement={"target"}
                                                position={"top"}
                                                parentTitle={true}
                                            >
                                                <div className="d-flex">
                                                {masterData?.data?.teamsRecordingMode.map((mode:MsTeamsRecordingMode)=>{
                                                return (
                                                    <span className="d-flex fs-13 mr-2">
                                                    <RadioButton
                                                        name={'editRecordingModes'}
                                                        value={editMsTeamsRecordingModeSelected}
                                                        checked={editMsTeamsRecordingModeSelected?.modeName===mode.modeName}
                                                        label={mode.modeName}
                                                        onChange={(e:RadioButtonChangeEvent)=>editMsTeamsRecordingModeChangeHandler(mode,device)}
                                                    />
                                                    <i className="bi bi-info-circle ml-1 tx-amber" title={mode.modeDescription}></i>
                                                    </span>
                                                )
                                                })}
                                                </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <label>{trans?.fetchLabelKeyTranslation("DialogEditLicencesTitle", "Licences")}</label>
                                            <CustomComboBox
                                            className="recordingpolicy-dropdown w-100 mt-0"
                                            data={customerInfo?.licensePackages
                                                ?.filter((l) => {
                                                return (
                                                    l.isAutoLearnEnabled ||
                                                    l.consumed < l.purchased
                                                );
                                                })
                                                .map((x) => {
                                                return masterData?.data?.licensePackages.find(y => y.id === x.id)
                                                })}
                                                textField="name"
                                                value={editLicensePackageSelected}
                                                onChange={(e:ComboBoxChangeEvent)=>editLicensePackageSelectedChangeHandler(e.value,device)}
                                                clearButton={false}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                )
                            })}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <DialogActionsBar>
                    {!isPolicyAssignmentValid && 
                    <div className="float-left fs-14 tx-red">
                        {trans?.fetchLabelKeyTranslation("DialogErrMssgOne", "you do not have enough licences to record selected users!")}
                    </div>
                    }
                    <Button className="btn bg-black-5" onClick={toggleUserDialog}>
                    {trans?.fetchLabelKeyTranslation("DialogCancelBtnText", "Cancel")}
                    </Button>
                    <Button 
                    className={`btn bg-primary text-white ${!isPolicyAssignmentValid || buttonStateTracker ? "disabledBtn" : ""}`}
                    onClick={updatePolicyAssignment}
                    disabled={!isPolicyAssignmentValid || buttonStateTracker}
                    >
                    {trans?.fetchLabelKeyTranslation("DialogUpdateBtnText", "Update")}
                    </Button>
                </DialogActionsBar>
                </>
            }
        </Dialog>
    )
}

export default RecordedUserDialogBox;