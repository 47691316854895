import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from "react";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import useLocale from "../../../hooks/useLocale";
import { CallResult } from "../../../types/call";
import { Dictionary } from "../../../types/Dictionary";
import { axiosWithAuth } from "../../../utils/customAxios";
import { CallResultWithReasonCode } from "./CallResult";
import { ReasonCodeInstance } from "../../../types/call/CallResultReasonCode";
import { TinyUser } from "../../../types/user";
import moment from "moment";
import 'moment/min/locales';
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import reasonCodeService from "../../../services/reasonCode.service";
import callResultService from "../../../services/callResult.service";
import useTranslation from "../../../hooks/useTranslation";

interface IReasonCodeDialogProps {
  toggleReasonCodeDialog: (data: ReasonCodeInstance | undefined) => void;
  upsertReasonCode: (
    isUpdated: boolean,
    reasonCode: ReasonCodeInstance | undefined,
    updatedCall: CallResultWithReasonCode | undefined
  ) => void;
  reasonCode: ReasonCodeInstance | undefined;
  user: TinyUser | undefined;
  call: CallResultWithReasonCode | undefined;
}

const EditReasonCodeDialogBox: React.FC<IReasonCodeDialogProps> = ({
  toggleReasonCodeDialog,
  upsertReasonCode,
  reasonCode,
  user,
  call,
}) => {
  const trans=useTranslation("EditReasonCodeDialogBox");
  const localeCtx = useLocale();
  const swal = useSwal();
  const formRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, seterror] = useState<string | undefined>();

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "EditReasonCodeDialogBox"
      ]
    ) {
      trans.fetchTranslations("EditReasonCodeDialogBox");
    }
  }, [localeCtx?.selectedLocale]);

  const handleUpsert = (reasonCodeVal: ReasonCodeInstance) => {
    seterror(undefined);
    setLoading(true);
    const reasonCodePost: ReasonCodeInstance = {
      id: reasonCodeVal?.id ?? 0,
      name: reasonCodeVal?.name ?? "",
      isArchived: reasonCodeVal?.isArchived ?? false,
      description: "",
      createdBy: user,
      updatedBy: null,
      createdAt: moment().toISOString(),
      updatedAt: null,
    };
    reasonCode
      ? reasonCodeService
          .updateReasonCode(reasonCodeVal, reasonCode?.id)
          .then((response) => {
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "success",
              title: trans.fetchLabelKeyTranslation("SwtAltUpdateTitle", "Updated"),
              text: trans.fetchLabelKeyTranslation(
                "SwtAltUpdateText",
                "Reason Code has been updated successfully"
              ),
              confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
            };
            swal.fire(swalOptions);
            upsertReasonCode(true, response, undefined);
            toggleReasonCodeDialog(undefined);
            setLoading(false);
          })
          .catch((err) => {
            if (
              err.response.data.error ===
              "Reason Code with same name already exists."
            ) {
              seterror(
                trans.fetchLabelKeyTranslation(
                  "InvalidNameTitle",
                  "Reason Code with same name already exists."
                )
              );
            } else if (err.response.data.error === "Method not Allowed") {
              seterror(
                trans.fetchLabelKeyTranslation(
                  "InvalidNameTitle2",
                  "You cannot update this reason code as it is assigned to a call"
                )
              );
            } else {
              seterror(
                trans.fetchLabelKeyTranslation(
                  "TextFailed",
                  "Something went wrong"
                )
              );
            }
            setLoading(false);
          })
      : reasonCodeService
          .createReasonCode(reasonCodePost)
          .then((response) => {
            const reasonCodes = [];
            call?.reasonCode.forEach((rc) => reasonCodes.push(rc.name));
            reasonCodes.push(reasonCodePost.name);
            callResultService
              .updateReasonCodesMappingWithCallResult(
                call?.callResult.id ?? 0,
                reasonCodes
              )
              .then((response) => {
                const swalOptions: SweetAlertOptions<any, any> = {
                  icon: "success",
                  title: trans.fetchLabelKeyTranslation(
                    "SwtAltCreatedTitle",
                    "Created"
                  ),
                  text: trans.fetchLabelKeyTranslation(
                    "SwtAltCreatedText",
                    "Reason Code has been created successfully"
                  ),
                  confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
                };
                swal.fire(swalOptions);
                if (call) {
                  const updatedCall: CallResultWithReasonCode = {
                    callResult: call?.callResult,
                    reasonCode: response,
                  };
                  upsertReasonCode(false, undefined, updatedCall);
                }
                toggleReasonCodeDialog(undefined);
              })
              .catch((error) => {
                seterror(
                  trans.fetchLabelKeyTranslation(
                    "TextFailed",
                    "Something went wrong"
                  )
                );
              });
            setLoading(false);
          })
          .catch((err) => {
            if (
              err.response.data.error ===
              "Reason Code with same name already exists."
            ) {
              seterror(
                trans.fetchLabelKeyTranslation(
                  "InvalidNameTitle",
                  "Reason Code with same name already exists."
                )
              );
            } else {
              seterror(
                trans.fetchLabelKeyTranslation(
                  "TextFailed",
                  "Something went wrong"
                )
              );
            }
            setLoading(false);
          });
  };

  const submitHandler = () => {
    const form = formRef.current as Form;
    const reasonCodeVal: ReasonCodeInstance = {
      id: reasonCode ? reasonCode?.id : 0,
      name: form?.values?.reasoncodeName,
      isArchived: reasonCode?.isArchived ?? false,
      description: "",
      createdBy: null,
      updatedBy: null,
      createdAt: null,
      updatedAt: null,
    };
    handleUpsert(reasonCodeVal);
  };

  const nameValidator = (value: string) => {
    if (!value.trim()) {
      return trans.fetchLabelKeyTranslation(
        "ReasonCodeNameEmptyTxt",
        "Reason Code name cannot be empty"
      );
    }
    if (value.length > 50) {
      return trans.fetchLabelKeyTranslation(
        "ReasonCodeNameLenghtTxt",
        "Reason Code name length cannot be more than 50!"
      );
    }

    return "";
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        reasoncodeName: reasonCode?.name ?? "",
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              reasonCode
                ? trans.fetchLabelKeyTranslation(
                  "ReasonCodeTitle1",
                  "Edit Reason Code"
                )
                : trans.fetchLabelKeyTranslation(
                  "ReasonCodeTitle2",
                  "Add Reason Code"
                )
            }
            onClose={() => toggleReasonCodeDialog(undefined)}
          >
            <div className="formAdd" style={{ marginBottom: "0px", minWidth: "250px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {trans.fetchLabelKeyTranslation(
                          "ReasonCodeText",
                          "Reason Code"
                        )}{" "}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="reasoncodeName"
                      name="reasoncodeName"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("reasoncodeName")}
                      placeholder={trans.fetchLabelKeyTranslation(
                        "ReasonCodeTextFieldPlaceholder",
                        "Enter Reason Code name..."
                      )}
                      component={CustomInput}
                      validator={nameValidator}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button
                className={`btn bg-black-5`}
                onClick={() => toggleReasonCodeDialog(undefined)}
              >
                {trans.fetchLabelKeyTranslation("ReasonCodeCancelText", "Cancel")}
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                onClick={submitHandler}
                disabled={!formRenderProps.valid}
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  trans.fetchLabelKeyTranslation("TextSave", "Save")
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default EditReasonCodeDialogBox;
