import React, { useDebugValue, useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import {
  Field,
  FormElement,
  Form,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  ComboBoxChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import "./UserProfile.scss";
import { TinyUser, User } from "../../../../../types/user";
import { Customer } from "../../../../../types/customer/Customer";
import { Loader } from "@progress/kendo-react-indicators";
import useLocale from "../../../../../hooks/useLocale";
import { Dictionary } from "../../../../../types/Dictionary";
import usersService from "../../../../../services/users.service";
import useAuth from "../../../../../hooks/useAuth";
import { AccessPermissionEnum } from "../../../../../enums";
import {
  UpsertUser,
  UpsertUserDevices,
} from "../../../../../types/user/UpsertUser";
import { AuthorizationProfile } from "../../../../../types/authorization-profile/AuthorizationProfile";
import { TinyObj } from "../../../../../types/TinyObj";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import useMasterData from "../../../../../hooks/useMasterData";
import CustomSearchFieldTextInput from "../../../../../components/custom/form/CustomSearchFieldTextInput";
import {
  RecordingNetworkAccessibilty,
  StorageDuration,
} from "../../../../../types/master-data";
import { Group } from "../../../../../types/user-groups/Group";
import CustomUserGridInfo from "../../../../../components/custom/grid/CustomUserGridInfo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { navData } from "../../../../../data/NavigationData";
import useSwal from "../../../../../hooks/useSwal";
import CustomComboBox from "../../../../../components/custom/form/CustomComboBox";
import CustomMultiSelect from "../../../../../components/custom/form/CustomMultiSelect";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TinyUserGroup } from "../../../../../types/user/UserGroupRelation";
import { sortUsersData } from "../../../../../utils/userUtil";
import useCustomNavigation from "../../../../../hooks/useCustomNavigation";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import useTranslation from "../../../../../hooks/useTranslation";

const apLocaleKeyName: { [key: string]: string } = {
  "Super Admin": "SuperAdmin",
  "Recording Admin": "RecordingAdmin",
  "Playback Supervisor": "PlaybackSupervisor",
  "Recording Agent": "RecordingAgent",
};

interface IUserRecordingNetworkGroup {
  groupDetail: TinyObj;
  users: User[];
}

interface ICustomTabTitleProp {
  name: string;
  count: number;
}

interface ICustomUserCardProp {
  user: User;
}

interface IUserPermissionsProps {
  user: User;
  authProfiles: AuthorizationProfile[] | undefined;
  userGroups: Group[] | undefined;
  freezeProfile: string;
  updateUserDetails: () => void;
  currentUser: User;
}

const CustomTabTitle = (props: ICustomTabTitleProp) => {
  return (
    <span>
      <span>{props.name}</span>
      <span className="tabBadge m-l-5">{props.count ? props.count : 0}</span>
    </span>
  );
};

const UserPermissions: React.FC<IUserPermissionsProps> = ({
  user,
  authProfiles,
  userGroups,
  freezeProfile,
  updateUserDetails,
  currentUser,
}) => {
  const trans = useTranslation("UserProfile")
  const customNav=useCustomNavigation();
  const localeCtx = useLocale();
  const auth = useAuth();
  const Swal = useSwal();
  const masterData = useMasterData();
  const scrollRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<User>(user);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedPlaybackNetworkTab, setSelectedPlaybackNetworkTab] =
    useState<number>(0);
  const [selectedUserGroupTab, setSelectedUserGroupTab] = useState<number>(0);
  const [addMoreUserDialogVisible, setAddMoreUserDialogVisible] =
    useState<boolean>(false);
  const [userRecordingNetworkGroups, setUserRecordingNetworkGroups] = useState<
    IUserRecordingNetworkGroup[]
  >([]);
  const [userRecordingNetwork, setUserRecordingNetwork] = useState<User[]>([]);
  const [authProfileData, setAuthProfileData] = useState<
    AuthorizationProfile[]
  >([]);
  var _tempMyPeople: User[] = [];

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["UserProfile"]
    ) {
      trans.fetchTranslations("UserProfile");
    } else {
      trans.setTranslations(
        localeCtx?.selectedLocale?.current.componentTranslations["UserProfile"]
      );
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    mapAuthProfileLocalization();
  }, [trans.translations]);

  useEffect(() => {
    updateUserRecordingNetwork();
  }, [userDetails,masterData?.standardUsers,masterData?.data?.recordingNetworkAccessibilties]);

  const mapAuthProfileLocalization = () => {
    if (authProfiles !== undefined) {
      let _authProfiles = [...authProfiles];
      _authProfiles = _authProfiles.map((ap) => {
        ap.localizationValue = ap.isSystemDefined
          ? trans.fetchLabelKeyTranslation(apLocaleKeyName[ap.name], ap.name)
          : ap.name;
        return ap;
      });
      setAuthProfileData(_authProfiles);
    }
  };

  const updateUserRecordingNetwork = () => {
    let _userRecordingNetworkGroups: IUserRecordingNetworkGroup[] = [];
    let _masterDataUsers = [
      ...(masterData?.standardUsers.filter((u) => !u.isArchived) ?? []),
    ];

    const currentViaPeople =
      masterData?.data?.recordingNetworkAccessibilties.find(
        (rna) => rna.id === userDetails.recordingNetworkAccessibilty?.id
      );
    let _userOrganization: IUserRecordingNetworkGroup = {
      groupDetail: {
        name: currentViaPeople?.localizationValue ?? "Organization",
        id: currentViaPeople?.id ?? -1,
      },
      users: [],
    };
    if (userDetails.recordingNetworkAccessibilty?.id === 1) {
      const _ownData = _masterDataUsers.filter(
        (su) => su.id === userDetails.id
      );
      if (_ownData) {
        _userOrganization.users = [..._ownData];
      }
    } else if (userDetails.recordingNetworkAccessibilty?.id === 2) {
      let _directReports = sortUsersData(
        _masterDataUsers.filter((su) => su.manager?.id === userDetails.id)
      );
      if (_directReports) {
        _userOrganization.users = [..._directReports];
      }
    } else if (userDetails.recordingNetworkAccessibilty?.id === 3) {
      _tempMyPeople = [];
      getMyPeople(userDetails.id);
      _userOrganization.users = sortUsersData([..._tempMyPeople]);
      _tempMyPeople = [];
    } else if (userDetails.recordingNetworkAccessibilty?.id === 4) {
      _userOrganization.users = sortUsersData([..._masterDataUsers]);
    }
    _userRecordingNetworkGroups.push(_userOrganization);

    // Adding associated groups playback network....
    userDetails?.associatedGroups?.playbackNetwork?.forEach((gp) => {
      const _group = userGroups?.find((ug) => ug.id === gp.id);
      if (_group) {
        let _userNetworkGroup: IUserRecordingNetworkGroup = {
          groupDetail: _group,
          users: [],
        };
        _group.groupMembers.forEach((gm) => {
          const _member = masterData?.standardUsers.find(
            (su) => su.id === gm.member.id
          );
          if (_member) {
            _userNetworkGroup.users.push(_member);
          }
        });
        _userRecordingNetworkGroups.push(_userNetworkGroup);
      }
    });

    let _networkUsers: User[] = [];
    _userRecordingNetworkGroups.forEach((gp) => {
      _networkUsers = [..._networkUsers, ...gp.users];
    });

    setUserRecordingNetwork(sortUsersData(_networkUsers.reduce((acc : User[], ele: User) => {
      if(acc.find(x => x.id == ele.id) === undefined)
      {
        acc.push(ele)
      }  
      return acc;
    }, [])));
    setUserRecordingNetworkGroups(_userRecordingNetworkGroups);
  };

  const getMyPeople = (userId: number) => {
    let _directReportsToUserId = masterData?.standardUsers.filter(
      (su) => su.manager?.id === userId
    );
    if (_directReportsToUserId && _directReportsToUserId.length > 0) {
      _directReportsToUserId.forEach((ele) => {
        if (_tempMyPeople.findIndex((tp) => tp.id === ele.id) === -1) {
          _tempMyPeople = [..._tempMyPeople, ele];
          getMyPeople(ele.id);
        }
      });
    }
  };

  const reset = () => {
    setUserDetails(user);
  };

  const getRetentionPeriodText = (duration: StorageDuration | undefined) => {
    if (duration === undefined || duration === null) {
      return "";
    } else {
      if (duration.durationType === "Unlimited") return duration.durationType;
      return duration.duration + " " + duration.durationType;
    }
  };

  const handlePlaybackNetworkTabSelection = (
    e: TabStripSelectEventArguments
  ) => {
    setSelectedPlaybackNetworkTab(e.selected);
  };

  const handleUserGroupTabSelection = (e: TabStripSelectEventArguments) => {
    setSelectedUserGroupTab(e.selected);
  };

  const toggleAddMoreUserDialog = () => {
    setAddMoreUserDialogVisible(!addMoreUserDialogVisible);
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  };

  const searchUser = (userDetails: User) => {
    var searchText = searchTerm.trim().toLocaleLowerCase();
    var firstName = userDetails.firstName ? userDetails?.firstName : "";
    var lastName = userDetails.lastName ? userDetails.lastName : "";
    var userName = `${firstName} ${lastName}`.trim();

    if (
      userDetails?.email?.toLocaleLowerCase().includes(searchText) ||
      userName?.toLocaleLowerCase().includes(searchText)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateUserInfo = async (authProfileData?:AuthorizationProfile,recordingNetworkData?:RecordingNetworkAccessibilty) => {
    const upsertUser: UpsertUser = {
      email: userDetails.email,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      managerEmailAddress: userDetails.manager?.email,
      countryName: userDetails.country?.name,
      timezoneIdentifier: userDetails.timezone?.timezoneIdentifier,
      localeCode: userDetails.locale?.code,
      loginEnabled: userDetails.loginEnabled,
      devices: userDetails.devices.map((ele) => {
        var upsertDevice: UpsertUserDevices = {
          devicePlatform: ele.platform,
          deviceIdentifier: ele.deviceIdentifier,
          recordingPolicy: ele.recordingPolicy
            ? {
                isIncludedForRecording:
                  ele.recordingPolicy?.isIncludedForRecording,
                callSubsetTypeName:
                  ele.recordingPolicy?.callSubset?.name ?? "All Calls",
              }
            : undefined,
        };
        return upsertDevice;
      }),
      authorizationProfileName:authProfileData?.name,
      recordingNetworkAccessibility:recordingNetworkData?.name,
      licensePackage: userDetails.licensePackage?.name,
      retentionPeriod: getRetentionPeriodText(userDetails.retentionPeriod),
    };
    const updatedUser = await usersService
      .updateUser(upsertUser, user.id)
      .then((data) => {})
      .catch((err) => {
        throw err;
      });
  };

  const updateRecordingNetwork = async (recordingGroupsData:TinyUserGroup[]) => {
    if (userDetails.associatedGroups !== null) {
      let updatedNetworkGroup = await usersService
        .updateRecordingNetworkViaGroup(
          recordingGroupsData,
          user.id
        )
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateUser=(recordingGroupsData:TinyUserGroup[],authProfileData?:AuthorizationProfile,recordingNetworkData?:RecordingNetworkAccessibilty)=>{
    setAddMoreUserDialogVisible(false);
    setLoading(true);
    Promise.all([updateUserInfo(authProfileData,recordingNetworkData), updateRecordingNetwork(recordingGroupsData)])
      .then((responses) => {
        Swal.fire({
          icon: "success",
          title: `${
            trans.fetchLabelKeyTranslation("SwtAltUpdatedTitle", "Updated")
          }`,
          text: `${
            trans.fetchLabelKeyTranslation(
                  "SwtAltUpdate",
                  "User has been updated"
                )
          }`,
          confirmButtonText: `${
            trans.fetchLabelKeyTranslation("OKText", "OK")
          }`,
        });
        updateUserDetails();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: `${
              trans.fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
            text: `${
              trans.fetchLabelKeyTranslation(
                    "SwtAltEARegistered",
                    "Email Already Register"
                  )
            }`,
            confirmButtonText: `${
              trans.fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        } else if (err.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: `${
              trans.fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
            text: `${
              trans.fetchLabelKeyTranslation(
                    "SwtAltURFailed",
                    "User Registration failed"
                  )
            }`,
            confirmButtonText: `${
              trans.fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `${
              trans.fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
            text: `${
              trans.fetchLabelKeyTranslation(
                    "SwtAltUpdateFailed",
                    "Error Updating User"
                  )
            }`,
            confirmButtonText: `${
              trans.fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        }
      })
      .finally(()=>{
        setLoading(false);
      })
  }

  const AddMoreUserDialog = () => {
    const [recordingNetworkAccessibilties, setRecordingNetworkAccessibilties] =
      useState<RecordingNetworkAccessibilty | undefined>(
        masterData?.data?.recordingNetworkAccessibilties.find(
          (rna) => rna.id === userDetails.recordingNetworkAccessibilty?.id
        )
      );
    const [playbackNetworkGroups, setPlaybackNetworkGroups] = useState<
      TinyUserGroup[]
    >(userDetails.associatedGroups?.playbackNetwork);
    const [buttonTrackUser, setButtonTrackUser] = useState<boolean>(true);

    const updatePlaybackNetwork = () => {
      setUserDetails({
        ...userDetails,
        recordingNetworkAccessibilty: recordingNetworkAccessibilties,
        associatedGroups: {
          ...userDetails.associatedGroups,
          playbackNetwork: playbackNetworkGroups,
        },
      });
      updateUser(playbackNetworkGroups,userDetails.authorizationProfile,recordingNetworkAccessibilties)
    };

    const compareState = (controlType: any, controlValue: any) => {
      if (controlType == "People") {
        if (
          controlValue?.name == user?.recordingNetworkAccessibilty?.name &&
          compareArray(
            playbackNetworkGroups,
            user.associatedGroups?.playbackNetwork
          )
        ) {
          setButtonTrackUser(true);
        } else {
          setButtonTrackUser(false);
        }
      } else if (controlType == "Group") {
        if (
          compareArray(controlValue, user.associatedGroups?.playbackNetwork) &&
          recordingNetworkAccessibilties?.name ==
            user.recordingNetworkAccessibilty?.name
        ) {
          setButtonTrackUser(true);
        } else {
          setButtonTrackUser(false);
        }
      }
    };

    const compareArray = (array1: any[], array2: any[]): boolean => {
      if ((!array1 && !array2) || (array1.length == 0 && !array2)) return true;

      if (array1?.length != array2?.length) return false;
      else {
        for (let i = 0; i < array1.length; i++) {
          if (array1[i].name != array2[i].name) return false;
        }
        return true;
      }
    };

    return (
      <div>
        <Form
          initialValues={{
            adViaPeople: recordingNetworkAccessibilties,
            addViaGroup: playbackNetworkGroups,
          }}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <Dialog
                title={
                  trans.fetchLabelKeyTranslation("EditUsersTitle", "Edit Users")
                }
                onClose={toggleAddMoreUserDialog}
              >
                <div
                  className="formBox p-t-15 p-b-15"
                  style={{ maxWidth: "450px" }}
                >
                  <div className="formBoxRow d-flex justify-content-between p-t-1 p-b-1">
                    <div className="formBoxLabel fs-14 text-black-8">
                      {trans.fetchLabelKeyTranslation(
                            "EditPlaybackNetworkTitle",
                            "Edit Playback Network"
                          )}
                    </div>
                  </div>
                  <div className="formBoxRow addGroupMultiProfile d-flex justify-content-between p-t-10 p-b-15">
                    <div
                      className="formBoxLabel fs-14 p-b-5"
                      style={{ minWidth: "160px" }}
                    >
                      <span className="fs-18 text-black-8 p-r-20">
                        <i className="bi bi-person"></i>
                      </span>
                      <span className="fs-14 text-black-11">
                        {trans.fetchLabelKeyTranslation(
                              "ViaPeopleTitle",
                              "Via People"
                            )}
                      </span>
                    </div>
                    <div className="formBoxAction w-100">
                      <div className="formControl">
                        <Field
                          clearButton={false}
                          id="adViaPeople"
                          name="adViaPeople"
                          data={
                            masterData?.data?.recordingNetworkAccessibilties
                          }
                          value={formRenderProps.valueGetter("adViaPeople")}
                          onChange={(e) => {
                            setRecordingNetworkAccessibilties(e.value);
                            compareState("People", e.value);
                          }}
                          component={CustomComboBox}
                          textField="localizationValue"
                          filtering={true}
                          disabled={
                            !auth?.checkUserAccess(
                              AccessPermissionEnum.ManageUsersAndGroups
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formBoxRow addGroupMultiProfile d-flex justify-content-between p-t-1 p-b-5">
                    <div
                      className="formBoxLabel fs-14 p-b-5"
                      style={{ minWidth: "160px" }}
                    >
                      <span className="fs-18 text-black-8 p-r-20">
                        <i className="bi bi-people"></i>
                      </span>
                      <span className="fs-14 text-black-11">
                        {trans.fetchLabelKeyTranslation(
                              "ViaUserGroupTitle",
                              "Via User Group"
                            )}
                      </span>
                    </div>
                    <div className="formBoxAction w-100">
                      <div className="formInput">
                        <Field
                          id="addViaGroup"
                          name="addViaGroup"
                          data={userGroups}
                          value={formRenderProps.valueGetter("addViaGroup")}
                          component={CustomMultiSelect}
                          textField="name"
                          filtering={true}
                          placeholder={
                            trans.fetchLabelKeyTranslation(
                                  "ViaUserGroupPlaceHolder",
                                  "Select Groups"
                                )
                          }
                          onChange={(e) => {
                            setPlaybackNetworkGroups(e.value);
                            compareState("Group", e.value);
                          }}
                          sorting={true}
                          sortField={"name"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <DialogActionsBar>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={toggleAddMoreUserDialog}
                  >
                    {`${
                      trans.fetchLabelKeyTranslation("CancelButton", "Cancel")
                    }`}
                  </button>
                  <Button
                    className={` ${
                      "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white" +
                      (buttonTrackUser ? "disabledBtn" : "")
                    }`}
                    onClick={updatePlaybackNetwork}
                    disabled={buttonTrackUser}
                  >
                    {`${
                      trans.fetchLabelKeyTranslation("UpdateButtonText", "Update")
                    }`}
                  </Button>
                </DialogActionsBar>
              </Dialog>
            </FormElement>
          )}
        />
      </div>
    );
  };

  const CustomUserCard = (props: ICustomUserCardProp) => {
    const user = props.user;
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const navigateToPermissions = () => {
      navigate(`/settings/user-control/user/${user.id}`, {
        state: { ...navData, from: location.pathname },
      });
    };

    return (
      <div className="cardListBlock">
        <div className="cardRows">
          <div className="cardColumn">
            <div className="colUser">
              <CustomUserGridInfo
                userInfo={{
                  id: user.id,
                  loginUserId: user.loginUserId,
                  firstName: user.firstName ? user.firstName : "",
                  lastName: user.lastName ? user.lastName : "",
                  image: user.image ? user.image : "",
                  email: user.email,
                  phoneNo: "",
                  isArchived: user.isArchived,
                }}
                navigateToProfile={{
                  navigate: true,
                  navigateFunction: navigateToPermissions,
                }}
              />
            </div>
            <div className="lblGroup float-left w-100">
              <div
                onClick={navigateToPermissions}
                className="fs-12 font-weight-semi p-t-7 p-b-7 text-truncate cursor-pointer"
              >
                <span>{user.authorizationProfile?.name}</span>
              </div>
              <div
                onClick={navigateToPermissions}
                className="fs-12 font-weight-semi p-t-7 p-b-7 text-truncate cursor-pointer"
              >
                <span className="float-left text-black-9 p-b-2">
                  License : &nbsp;
                </span>
                <span>{user.licensePackage ? user.licensePackage?.name : "None"}</span>
              </div>
              <div
                onClick={navigateToPermissions}
                className="minTextLabel p-t-1 cursor-pointer"
              >
                <span
                  className="float-left text-black-9 p-b-2"
                  style={{ minWidth: "77px" }}
                >
                  Recorded Device:
                </span>
                <span className="p-l-3">
                  <span className="badgeList">
                    <div className="keywordRow">
                      {user.devices.length > 0 ? (
                        user.devices.map((device) => {
                          return (
                            <div
                              className="keywordTrk"
                              key={device.deviceIdentifier}
                            >
                              {device.deviceIdentifier}
                            </div>
                          );
                        })
                      ) : (
                        <div className="keywordTrk">None</div>
                      )}
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="blockIconButton">
          {(currentUser?.recordingNetwork?.find(
            (u: TinyUser) => u.id === user.id
          ) &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)) ||
          auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl) ? (
            <span className="btnBlocks">
              <Link
                to={`/settings/user-control/user/${user.id}`}
                className="btnBlock-a"
                onClick={navigateToPermissions}
              >
                <i className="bi bi-pencil fs-17"></i>
              </Link>
            </span>
          ) : (
            <span className="btnBlocks">
              <a
                className="btnBlock-a cursor-pointer"
                title="View User Profile"
              >
                <i className="bi bi-eye fs-17"></i>
              </a>
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && (
        <LoadingOverlay
          customStyle={{ position: "fixed" }}
          themeColor={"light"}
          size={"medium"}
        />
      )}
    <Tooltip anchorElement={"target"} parentTitle={true} position={"top"}>
      <div className="tabInner">
        <div className="row">
          <div className="col-md-12">
            <div className="detailSection p-t-16 p-r-15 p-l-25">
              <div className="detailRow">
                <div className="float-left w-100">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="detailCol">
                        <div className="detailColBox">
                          <div className="formBox">
                            <div className="formBoxRow d-flex justify-content-between align-items-center p-t-7 p-b-7">
                              <div className="formBoxLabel fs-15 font-weight-semi">
                                {trans.fetchLabelKeyTranslation(
                                      "PermissionGroupTitle",
                                      "Permission Group"
                                    )}
                              </div>
                              <div className="formBoxAction">
                                <div className="switchButton">
                                  <CustomComboBox
                                    style={{ height: "32px" }}
                                    data={
                                      freezeProfile == "true"
                                        ? authProfileData.filter(
                                            (pro) =>
                                              (pro.id === 1 &&
                                                userDetails.authorizationProfile
                                                  ?.id === 1) ||
                                              (pro.id !== 1 &&
                                                userDetails.authorizationProfile
                                                  ?.id !== 1)
                                          )
                                        : authProfileData
                                    }
                                    value={
                                      userDetails.authorizationProfile
                                        ? authProfileData.find(
                                            (ap) =>
                                              ap.id ===
                                              userDetails.authorizationProfile
                                                ?.id
                                          )
                                        : ""
                                    }
                                    onChange={(e: ComboBoxChangeEvent) => {
                                      if(e.value){
                                        setUserDetails({
                                          ...userDetails,
                                          authorizationProfile: e.value,
                                        });
                                        updateUser(userDetails.associatedGroups.playbackNetwork,e.value,userDetails.recordingNetworkAccessibilty)
                                      }
                                    }}
                                    textField="localizationValue"
                                    disabled={
                                      freezeProfile == "true"
                                        ? userDetails.authorizationProfile
                                            ?.id == 1 ||
                                          !auth?.checkUserAccess(
                                            AccessPermissionEnum.ManageSecurityControl
                                          )
                                        : !auth?.checkUserAccess(
                                            AccessPermissionEnum.ManageSecurityControl
                                          ) ||
                                          masterData?.telepoDisableConfig
                                            ?.usermanagement.users.profile
                                            .disablePermissionGroup
                                    }
                                    filtering={true}
                                    sorting={true}
                                    sortField={"localizationValue"}
                                    clearButton={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="formBoxRow p-t-5 p-b-5">
                              <div className="formBoxLabel fs-14 text-black-8">
                                {trans.fetchLabelKeyTranslation(
                                      "AllPermissionsTitle",
                                      "All Permissions"
                                    )}
                              </div>
                            </div>
                            <div
                              className="allPermissionsList float-left scroller"
                              style={{
                                maxHeight: "350px",
                                overflow: "hidden auto",
                              }}
                            >
                              {masterData?.data?.accessPermissions.map(
                                (ele) => {
                                  if (
                                    ele.id === 8 &&
                                    !auth?.checkTopicAvailibility()
                                  ) {
                                    return;
                                  }
                                  const pIndex =
                                    userDetails.authorizationProfile?.permissions.findIndex(
                                      (p) => p.id === ele.id
                                    );
                                  const granted =
                                    pIndex !== undefined && pIndex > -1;
                                  return (
                                    <div
                                      key={ele.id}
                                      className={`formBoxRow ${
                                        granted ? "" : "liUnChecked"
                                      } d-flex justify-content-between align-items-center p-t-12 p-b-12 border-bottom-solid border-w-1 border-black-1`}
                                    >
                                      <div className="formBoxLabel">
                                        <span
                                          className="fs-14 p-r-8 text-black-9"
                                          title={
                                            ele.isPlaybackPermission
                                              ? "Playback Permission"
                                              : ""
                                          }
                                        >
                                          <i
                                            className={`bi ${
                                              ele.isPlaybackPermission
                                                ? "bi-collection-play-fill"
                                                : "bi-shield-lock-fill"
                                            }`}
                                          ></i>
                                        </span>
                                        <span className="fs-14">
                                          {ele.localizationValue}
                                        </span>
                                      </div>
                                      {granted && (
                                        <i className="bi bi-check-circle fs-20 text-success p-r-10"></i>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 cardLab cardLabRow">
                      <div className="detailColOuterLabel h-100 w-100 float-left p-l-15 border-l-1 border-solid-black border-left-solid border-left-w-1 border-black-1">
                        <div className="detailCol">
                          <div className="titleWithTab float-left w-100 p-l-10 p-r-10">
                            <div className="detailColTitle float-left w-100 fs-15 text-default font-weight-semi p-b-5">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>
                                  {trans.fetchLabelKeyTranslation(
                                        "PlaybackNetworkTitle",
                                        "Playback Network"
                                      )}
                                </span>
                                <div
                                  className="leftProControl d-flex align-items-center"
                                  style={{
                                    margin: "-5px 0 0 0",
                                    padding: "0 0 5px 0",
                                  }}
                                >
                                  <div className="searchCol searchBox m-r-10">
                                    <CustomSearchFieldTextInput
                                      className="input-search"
                                      placeholder={
                                        trans.fetchLabelKeyTranslation(
                                              "SearchBoxPlaceHolder",
                                              "Search…"
                                            )
                                      }
                                      value={searchTerm}
                                      onEscapeKeyFunc={true}
                                      searchTextChangeHandler={
                                        handleSearchChange
                                      }
                                      updateValue={setSearchTerm}
                                    />
                                  </div>
                                  <Button
                                    onClick={toggleAddMoreUserDialog}
                                    disabled={
                                      !auth?.checkUserAccess(
                                        AccessPermissionEnum.ManageSecurityControl
                                      ) ||
                                      masterData?.telepoDisableConfig
                                        ?.usermanagement.users.profile
                                        .disablePlaybackNetwork
                                    }
                                    className={`btn bg-primary text-white fs-13 fw-normal  ${
                                      !auth?.checkUserAccess(
                                        AccessPermissionEnum.ManageSecurityControl
                                      ) ||
                                      masterData?.telepoDisableConfig
                                        ?.usermanagement.users.profile
                                        .disablePlaybackNetwork
                                        ? "disabledBtn"
                                        : ""
                                    }`}
                                    style={{
                                      height: "29px",
                                      margin: "-1px 0 0 0",
                                    }}
                                    title={
                                      trans.fetchLabelKeyTranslation(
                                            "EditButtonTitle",
                                            "Edit"
                                          )
                                    }
                                  >
                                    {trans.fetchLabelKeyTranslation(
                                          "EditButtonText",
                                          "Edit"
                                        )}
                                  </Button>
                                  {addMoreUserDialogVisible && (
                                    <AddMoreUserDialog />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="detailColBox float-left w-100">
                            <div className="formBox">
                              <div className="dvcList p-t-1">
                                <div className="listOverflowDvc float-left w-100 p-12 p-t-1">
                                  <div className="cardPro">
                                    <div className="tabLabel tabLeftPadding-0 h-100">
                                      <TabStrip
                                        selected={selectedPlaybackNetworkTab}
                                        onSelect={
                                          handlePlaybackNetworkTabSelection
                                        }
                                      >
                                        <TabStripTab
                                          title={
                                            <CustomTabTitle
                                              name="People"
                                              count={
                                                userRecordingNetwork.filter(
                                                  (ele) => searchUser(ele)
                                                ).length
                                              }
                                            />
                                          }
                                        >
                                          <div className="cardBrand">
                                            <div className="cardListArea">
                                              <div className="row">
                                                {userRecordingNetwork &&
                                                userRecordingNetwork.filter(
                                                  (ele) => searchUser(ele)
                                                ).length > 0 ? (
                                                  userRecordingNetwork
                                                    .filter((ele) =>
                                                      searchUser(ele)
                                                    )
                                                    .map((_user) => {
                                                      return (
                                                        <div className="col col-md-4" key={_user.id}>
                                                          <CustomUserCard
                                                            user={_user}
                                                          />
                                                        </div>
                                                      );
                                                    })
                                                ) : (
                                                  <div className="col-md-12 p-l-5">
                                                    <div className="dashedBox p-10 m-b-20 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center">
                                                      <span className="fs-13 text-black-9 p-t-20 p-b-20">
                                                        {trans.fetchLabelKeyTranslation(
                                                              "UserManagementInfoMsgNoUsers",
                                                              "No Users Available"
                                                            )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </TabStripTab>
                                        <TabStripTab
                                          title={
                                            <CustomTabTitle
                                              name="User Group"
                                              count={
                                                userRecordingNetworkGroups.filter(
                                                  (ele) =>
                                                    ele.users.filter((u) =>
                                                      searchUser(u)
                                                    ).length > 0
                                                ).length
                                              }
                                            />
                                          }
                                        >
                                          <div className="cardBrand">
                                            <div className="navSidePro w-100 p-t-12">
                                              <TabStrip
                                                selected={selectedUserGroupTab}
                                                onSelect={
                                                  handleUserGroupTabSelection
                                                }
                                                tabPosition="left"
                                              >
                                                {userRecordingNetworkGroups
                                                  .filter(
                                                    (ele) =>
                                                      ele.users.filter((u) =>
                                                        searchUser(u)
                                                      ).length > 0
                                                  )
                                                  .map((_group) => {
                                                    return (
                                                      <TabStripTab
                                                        title={
                                                          _group.groupDetail
                                                            .name
                                                        }
                                                      >
                                                        <div className="navSideProInr p-l-17">
                                                          {_group.users &&
                                                          _group.users.filter(
                                                            (u) => searchUser(u)
                                                          ).length > 0 ? (
                                                            <div className="cardListArea">
                                                              <div className="row">
                                                                {_group.users
                                                                  .filter((u) =>
                                                                    searchUser(
                                                                      u
                                                                    )
                                                                  )
                                                                  .map(
                                                                    (
                                                                      _member
                                                                    ) => {
                                                                      return (
                                                                        <div className="col col-md-4">
                                                                          <CustomUserCard
                                                                            user={
                                                                              _member
                                                                            }
                                                                          />
                                                                        </div>
                                                                      );
                                                                    }
                                                                  )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <div
                                                              style={{
                                                                minHeight:
                                                                  "200px",
                                                              }}
                                                              className="p-10 m-b-20 text-black-9 d-flex align-items-center justify-content-center radius-6 border-black-3 border-w-2 border-dashed"
                                                            >
                                                              {trans.fetchLabelKeyTranslation(
                                                                    "UserManagementInfoMsgNoUsers",
                                                                    "No Users Available"
                                                                  )}
                                                            </div>
                                                          )}
                                                        </div>
                                                      </TabStripTab>
                                                    );
                                                  })}
                                              </TabStrip>
                                            </div>
                                          </div>
                                        </TabStripTab>
                                      </TabStrip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
    </>
  );
};

export default UserPermissions;
