import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from "react";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import useLocale from "../../../hooks/useLocale";
import { CallResult } from "../../../types/call";
import { Dictionary } from "../../../types/Dictionary";
import { axiosWithAuth } from "../../../utils/customAxios";
import { CallResultWithReasonCode } from "./CallResult";
import { ReasonCodeInstance } from "../../../types/call/CallResultReasonCode";
import {
  ListItemProps,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import CustomMultiSelect from "../../../components/custom/form/CustomMultiSelect";
import callResultService from "../../../services/callResult.service";
import { AxiosError } from "axios";
import useTranslation from "../../../hooks/useTranslation";

interface ICallResultDialogProps {
  toggleDialog: () => void;
  callResult?: CallResultWithReasonCode;
  upsertCallResult: (
    isUpdated: boolean,
    callResult: CallResultWithReasonCode
  ) => void;
  reasonCodes: ReasonCodeInstance[] | undefined;
}

const UpsertCallResultDialog: React.FC<ICallResultDialogProps> = ({
  toggleDialog,
  callResult,
  upsertCallResult,
  reasonCodes,
}) => {
  const trans=useTranslation("UpsertCallResultDialog");
  const localeCtx = useLocale();
  const swal = useSwal();
  const formRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, seterror] = useState<string | undefined>();
  const [reasonCodesDropdownValue, setReasonCodesDropdownValue] = useState<
    ReasonCodeInstance[] | undefined
  >(callResult?.reasonCode);

  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertCallResultDialog"
      ]
    ) {
      trans.fetchTranslations("UpsertCallResultDialog");
    }
  }, [localeCtx?.selectedLocale]);

  const handleUpsert = async (callResultVal: CallResult) => {
    seterror(undefined);
    setLoading(true);
    const data: string[] = [];
    reasonCodesDropdownValue?.forEach((rc) => data.push(rc.name));
    callResult
      ? callResultService
        .updateCallResult(callResult.callResult.id, callResultVal)
        .then((response) => {
          callResultService
            .updateReasonCodesMappingWithCallResult(callResultVal.id, data)
            .then((reasonCodesMapping) => {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "success",
                title: trans.fetchLabelKeyTranslation("SwtAltUpdateTitle", "Updated"),
                text: trans.fetchLabelKeyTranslation(
                  "SwtAltUpdateText",
                  "Call Outcome has been updated successfully"
                ),
                confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
              };
              swal.fire(swalOptions);
              upsertCallResult(true, {
                callResult: response,
                reasonCode: reasonCodesMapping,
              });
              toggleDialog();
            })
            .catch((err) => {
              if (err instanceof AxiosError) {
                seterror(
                  trans.fetchLabelKeyTranslation(
                    "TextFailed",
                    "Something went wrong"
                  )
                );
              }
            });
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            seterror(trans.fetchLabelKeyTranslation(
              "InvalidLengthTitle",
              "Invalid length of Call Outcome."
            ))
          }
          if (err.response?.status === 403) {
            seterror(trans.fetchLabelKeyTranslation(
              "InvalidCustomerTitle",
              "Invalid CustomerId or UserId"
            ))
          }
          if (err.response?.status === 404) {
            seterror(trans.fetchLabelKeyTranslation(
              "NotFoundErrorTitle",
              "Call Outcome Not Found"
            ))
          }
          else if (err.response.status === 406 && err.response.data.error === "Name already exists.") {
            seterror(
              trans.fetchLabelKeyTranslation(
                "InvalidNameTitle",
                "Call Outcome with same name already exists."
              )
            );
          }
          else if (err.response.status == 406) {
            toggleDialog();
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "info",
              title: trans.fetchLabelKeyTranslation(
                "SwtAltUpdateFailedTitle",
                "Call Outcome cannot be updated"
              ),
              text: trans.fetchLabelKeyTranslation(
                "SwtAltUpdateFailedText",
                "You can archive the Call Outcome"
              ),
              showCancelButton: true,
              confirmButtonText: trans.fetchLabelKeyTranslation(
                "SwtAltCfnArchiveText",
                "Yes, Archive it!"
              ),
              cancelButtonText: trans.fetchLabelKeyTranslation("CancelText", "Cancel"),
            };
            swal.fire(swalOptions).then(async (result) => {
              if (result.isConfirmed) {
                const updatedCallResult: CallResultWithReasonCode =
                  callResult;
                updatedCallResult.callResult.isArchived = true;
                callResultService
                  .updateCallResult(
                    callResult.callResult.id,
                    updatedCallResult.callResult
                  )
                  .then((response) => {
                    const swalOptions: SweetAlertOptions<any, any> = {
                      icon: "success",
                      title: trans.fetchLabelKeyTranslation(
                        "SwtArchiveSuccessTitle",
                        "Call Outcome Archived "
                      ),
                      text: trans.fetchLabelKeyTranslation(
                        "SwtArchiveSuccessText",
                        "Call Outcome has been archived successfully"
                      ),
                      confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
                    };
                    swal.fire(swalOptions);
                    upsertCallResult(true, {
                      callResult: response,
                      reasonCode: updatedCallResult.reasonCode,
                    });
                    toggleDialog();
                  });
              }
            });
          }
          else {
            seterror(
              trans.fetchLabelKeyTranslation(
                "TextFailed",
                "Something went wrong"
              )
            );
          }
          setLoading(false);
        })
      : callResultService
        .createCallResult(callResultVal)
        .then((response) => {
          callResultService
            .updateReasonCodesMappingWithCallResult(response.id, data)
            .then((reasonCodesMapping) => {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "success",
                title: trans.fetchLabelKeyTranslation(
                  "SwtAltCreatedTitle",
                  "Created"
                ),
                text: trans.fetchLabelKeyTranslation(
                  "SwtAltCreatedText",
                  "Call Outcome has been created successfully"
                ),
                confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
              };
              swal.fire(swalOptions);
              upsertCallResult(false, {
                callResult: response,
                reasonCode: reasonCodesMapping,
              });
              toggleDialog();
            })
            .catch((err) => {
              seterror(
                trans.fetchLabelKeyTranslation(
                  "TextFailed",
                  "Something went wrong"
                )
              );
            });
          setLoading(false);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            if (err.response?.status === 400) {
              seterror(trans.fetchLabelKeyTranslation(
                "InvalidLengthTitle",
                "Invalid length of Call Outcome."
              ))
            }
            else if (err.response?.status === 403) {
              seterror(trans.fetchLabelKeyTranslation(
                "InvalidCustomerTitle",
                "Invalid CustomerId or UserId"
              ))
            }
            else if (err.response?.status === 406) {
              seterror(
                trans.fetchLabelKeyTranslation(
                  "InvalidNameTitle",
                  "Call Outcome with same name already exists."
                )
              );
            }
            else {
              seterror(
                trans.fetchLabelKeyTranslation(
                  "TextFailed",
                  "Something went wrong"
                )
              );
            }
          }
          setLoading(false);
        });
  };

  const submitHandler = () => {
    const form = formRef.current as Form;
    const callResultVal: CallResult = {
      id: callResult ? callResult.callResult.id : 0,
      text: form.values.text,
    };
    handleUpsert(callResultVal);
  };

  const nameValidator = (value: string) => {
    if (!value.trim()) {
      return trans.fetchLabelKeyTranslation(
        "CallResNameEmptyTxt",
        "Call Outcome text cannot be empty"
      );
    }
    if (value.length > 100) {
      return trans.fetchLabelKeyTranslation(
        "CallResNameLenghtTxt",
        "Call Outcome text length cannot be more than 100!"
      );
    }

    return "";
  };

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <Tooltip anchorElement="target" parentTitle={true} position="top">
          <div className="keywordRow">
            <div className="keywordTrk bg-darkkhaki">
              <span>{itemProps.dataItem.name}</span>
            </div>
          </div>
        </Tooltip>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleReasonCodeDropdown = (event: MultiSelectChangeEvent) => {
    setReasonCodesDropdownValue(event.value);
  };

  const compareState = (controlName: any, controlValue: any) => {
    const form = formRef.current as Form;
    if (controlName == "ReasonCodes") {
      if (
        compareArray(
          callResult?.reasonCode ? callResult?.reasonCode.slice() : [],
          controlValue
        ) &&
        form.values.text == callResult?.callResult?.text
      ) {
        setButtonStateTracker(true);
      } else {
        setButtonStateTracker(false);
      }
    } else if (
      compareArray(
        callResult?.reasonCode ? callResult?.reasonCode.slice() : [],
        reasonCodesDropdownValue ? reasonCodesDropdownValue : []
      ) &&
      form.values.text == callResult?.callResult?.text
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  const compareArray = (array1: any[], array2: any[]): boolean => {
    array1.sort(function (a, b) {
      if (a.name > b.name) return 1;
      else return -1;
    });

    array2.sort(function (a, b) {
      if (a.name > b.name) return 1;
      else return -1;
    });

    if (array1.length != array2.length) return false;
    else {
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].name != array2[i].name) return false;
      }
      return true;
    }
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        text: callResult?.callResult?.text ?? "",
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              callResult
                ? trans.fetchLabelKeyTranslation(
                  "CallResTitle1",
                  "Edit Call Outcome"
                )
                : trans.fetchLabelKeyTranslation(
                  "CallResTitle2",
                  "Add Call Outcome"
                )
            }
            onClose={toggleDialog}
          >
            <div className="formAdd" style={{ marginBottom: "0px", minWidth: "250px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {trans.fetchLabelKeyTranslation("OutcomeField", "Call Outcome")}{" "}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="scorecardName"
                      name="text"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("scorecardName")}
                      placeholder={trans.fetchLabelKeyTranslation(
                        "OutcomeFieldPlaceholder",
                        "Enter Call Outcome"
                      )}
                      component={CustomInput}
                      validator={nameValidator}
                      onChange={(e) => {
                        compareState("Text", e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="inputRow p-b-5">
                <div className="inputLabel">
                  {loading
                    ? "Reason Code"
                    : trans.fetchLabelKeyTranslation("ReasonCodeText", "Reason Code")}
                </div>
                <CustomMultiSelect
                  data={reasonCodes}
                  value={reasonCodesDropdownValue}
                  onChange={(e: MultiSelectChangeEvent) => {
                    handleReasonCodeDropdown(e);
                    compareState("ReasonCodes", e.value);
                  }}
                  textField="name"
                  itemRender={itemRenderUser}
                  dataItemKey="id"
                  filtering={true}
                  sorting={true}
                  sortField={"name"}
                />
              </div>
            </div>
            <DialogActionsBar>
              <Button className={`btn bg - black - 5`} onClick={toggleDialog}>
                {trans.fetchLabelKeyTranslation("CancelText", "Cancel")}
              </Button>
              <Button
                className={` ${"k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white" +
                  ((
                    (!formRenderProps.valid || buttonStateTracker)
                      ? true
                      : false
                  )
                    ? "disabledBtn"
                    : "")
                  } `}
                onClick={submitHandler}
                disabled={
                  (!formRenderProps.valid || buttonStateTracker)
                    ? true
                    : false
                }
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  `${callResult
                    ? trans.fetchLabelKeyTranslation("UpdateButton", "Update")
                    : trans.fetchLabelKeyTranslation("AddButton", "Add")
                  } `
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertCallResultDialog;
