import { Comment } from "../../types/recording";
import { TinyUser } from "../../types/user";
import ContentPlaceholder from "../ContentPlaceholder";
import RecordingCommentItem from "./RecordingCommentItem";

interface TinyUserSelect extends TinyUser {
  checked?: boolean;
}

interface RecordingCommentsListProps {
  recordingId: number;
  comments: Comment[];
  audioLoaded: boolean;
  disableCommentPlay: boolean;
  stopCommentPlay: boolean;
  playedCommentId: number;
  shared?: boolean;
  onDeleteComment: (id: number) => void;
  onUpdateComment: (updatedComment: Comment) => void;
  onCommentMouseOver: (commentId: number) => void;
  onCommentMouseOut: () => void;
  onUserCommentPlay: (comment: Comment) => void;
  onUserCommentStop: () => void;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  platformCallDetailId?: string;
  internalUsers: TinyUserSelect[];
  recordingPath: string;
  setTotalCommentsTemp: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  totalCommentsTemp?: number;
}

const RecordingCommentsList: React.FC<RecordingCommentsListProps> = ({
  recordingId,
  comments,
  audioLoaded,
  disableCommentPlay,
  stopCommentPlay,
  playedCommentId,
  shared,
  onDeleteComment,
  onUpdateComment,
  onCommentMouseOver,
  onCommentMouseOut,
  onUserCommentPlay,
  onUserCommentStop,
  fetchLabelKeyTranslation,
  platformCallDetailId,
  internalUsers,
  recordingPath,
  setTotalCommentsTemp,
  totalCommentsTemp,
}) => {
  return (
    <div className="col-md-7">
      <div className="chat-bx">
        <div className="chat-conversation">
          {comments && comments.length === 0 && (
            <ContentPlaceholder>
              {fetchLabelKeyTranslation("NoCommentDisplayMessage", "There are no comments for this call.")}
            </ContentPlaceholder>
          )}
          <div
            className="chatBox scroller w-100"
            style={{ maxHeight: "310px", overflow: "auto" }}
          >
            <ul className="list-unstyled mb-0">
              {/* Map through all the recording comments. */}
              {comments &&
                comments.map((item) => {
                  return (
                    <li key={item.id}>
                      <RecordingCommentItem
                        recordingId={recordingId}
                        comment={item}
                        audioLoaded={audioLoaded}
                        disableCommentPlay={disableCommentPlay}
                        stopCommentPlay={stopCommentPlay}
                        playedCommentId={playedCommentId}
                        onDeleteComment={onDeleteComment}
                        onUpdateComment={onUpdateComment}
                        onCommentMouseOver={onCommentMouseOver}
                        onCommentMouseOut={onCommentMouseOut}
                        onUserCommentPlay={onUserCommentPlay}
                        onUserCommentStop={onUserCommentStop}
                        shared={shared}
                        fetchLabelKeyTranslation={fetchLabelKeyTranslation}
                        platformCallDetailId={platformCallDetailId}
                        internalUsers={internalUsers}
                        recordingPath={recordingPath}
                        setTotalCommentsTemp={setTotalCommentsTemp}
                        totalCommentsTemp={totalCommentsTemp}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordingCommentsList;
