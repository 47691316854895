import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  InputChangeEvent,
} from "@progress/kendo-react-inputs";
import { useEffect, useState } from "react";
import useLocale from "../../../../../hooks/useLocale";
import deviceService from "../../../../../services/device.service";
import { DeviceAllocation } from "../../../../../types/device/deviceAllocation";
import { Dictionary } from "../../../../../types/Dictionary";
import { PlatformUserDetail, TinyUser, User } from "../../../../../types/user";
import useSwal from "../../../../../hooks/useSwal";
import useTranslation from "../../../../../hooks/useTranslation";
import { Loader } from "@progress/kendo-react-indicators";

interface AddDeviceDialogProps {
  toggleDialog: () => void;
  userDevices: PlatformUserDetail[];
  upsertDevice: (devices: PlatformUserDetail[]) => void;
  user: User;
}

interface PlatformUserDetailWithCheckbox {
  allocation: DeviceAllocation;
  isSelected: boolean;
}

const AddDeviceDialog: React.FC<AddDeviceDialogProps> = ({
  toggleDialog,
  userDevices,
  upsertDevice,
  user,
}) => {
  const userTinyUserObj: TinyUser = {
    id: user.id,
    loginUserId: user.loginUserId,
    firstName: user.firstName ?? "",
    lastName: user.lastName ?? "",
    email: user.email,
    isArchived: user.isArchived,
  };
  const trans = useTranslation("AddDeviceDialog")
  const Swal = useSwal();
  const localeCtx = useLocale();
  const [devices, setDevices] = useState<PlatformUserDetailWithCheckbox[]>([]);
  const [loading,setLoading]=useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceAllocation[]>(
    userDevices.map((item) => {
      let deviceAlloc: DeviceAllocation = {
        device: item,
        user: userTinyUserObj
      };
      return deviceAlloc;
    })
  );
  const [searchText, setSearchText] = useState<string>("");
  const [disableAttach, setDisableAttach] = useState<boolean>(false);

  useEffect(() => {
    setDevicesState();
  }, []);

  useEffect(() => {
    setDisableAttach(
      devices.filter(
        (d) => d.allocation.user?.id !== user.id && d.isSelected === true
      ).length === 0
    );
  }, [devices]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "AddDeviceDialog"
      ]
    ) {
      trans.fetchTranslations("AddDeviceDialog");
    }
  }, [localeCtx?.selectedLocale]);

  const updateSearchText = (e: InputChangeEvent) => {
    setSearchText(e.value);
  };

  const setDevicesState = async () => {
    setLoading(true);
    try{
      const allDevice = await deviceService.getAllCustomerDevice();
      const allDevicewitCheckedValue = allDevice.map((dev) => {
      const deviceSet: PlatformUserDetailWithCheckbox = {
        allocation: dev,
        isSelected: false,
      };
      selectedDevice.map((item) => {
        if (item.device.deviceIdentifier === dev.device.deviceIdentifier) {
          deviceSet.isSelected = true;
          deviceSet.allocation = item;
        }
      });
      return deviceSet;
      });
      setDevices(allDevicewitCheckedValue);
    } catch(error) {
      setDevices([])
    } finally{
      setLoading(false);
    }
  };

  const attachDevice = () => {
    upsertDevice(
      devices
        .filter((dev) => dev.isSelected)
        .map((item) => {
          return item.allocation.device;
        })
    );
    toggleDialog();
  };

  const upsertSelectedDevice = (
    changedevice: PlatformUserDetailWithCheckbox,
    e: CheckboxChangeEvent
  ) => {
    setDevices(
      devices.map((item) => {
        if (
          item.allocation.device.deviceIdentifier ===
          changedevice.allocation.device.deviceIdentifier
        ) {
          item.isSelected = e.value;
        }
        return item;
      })
    );
  };

  const searchDevice = (device: PlatformUserDetailWithCheckbox) => {
    const text = searchText.trim().toLocaleLowerCase();
    if (device.allocation.device.platform.toLocaleLowerCase().includes(text)
      || device.allocation.device.deviceIdentifier.toLocaleLowerCase().includes(text)
    ){
      return true;
    }
    return false;
  };

  return (
    <Dialog
      title={`${
        trans.fetchLabelKeyTranslation("AttachDeviceTitle", "Attach Device")
      }`}
      onClose={toggleDialog}
    >
      <div
        className="attachDevieBox"
        style={{
          minWidth: "400px",
          maxWidth: "400px",
          minHeight: "200px",
        }}
      >
        <div className="detailCol">
          <div className="detailColBox">
            <div className="formBox">
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxAction">
                  <div className="detailColBox p-r-10">
                    <div className="UserLiSearch p-b-5">
                      <div className="formInput">
                        <Input
                          style={{ height: "32px" }}
                          placeholder={`${
                            trans.fetchLabelKeyTranslation(
                                  "SearchbBoxPlaceHolder",
                                  "Search…"
                                )
                          }`}
                          onChange={updateSearchText}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    {loading ?
                    <div className="d-flex justify-content-center">  
                      <Loader
                        size={"medium"}
                        type={"infinite-spinner"}
                        themeColor={"dark"}
                      />
                    </div> : devices.length===0 ? <span className="d-flex justify-content-center">No Devices Available</span>
                    :<div className="detailColBox">
                      <div className="dashedBox p-10 m-b-10 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed">
                        <div
                          className="scroller"
                          style={{
                            maxHeight: "285px",
                            minHeight: "285px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            paddingRight: "20px",
                          }}
                        >
                          {devices
                            .filter((item) => searchDevice(item))
                            .map((item) => {
                              return (
                                <div
                                  key={item.allocation.device.deviceIdentifier}
                                  className="UserRow w-100 float-left p-t-4 p-b-4 border-bottom-solid border-w-1 border-black-1"
                                >
                                  <div className="deviceListing">
                                    <div className="usrTableRow d-flex align-items-center justify-content-between">
                                      <div className="userTblCol">
                                        <div className="tblUsr">
                                          <div className="topUsrAreaPic">
                                            <div className="topUsrAreaPic-i">
                                              <div className="contact-list-icon bg-darksteelblue">
                                                <div className="contact-list-icon-txt">
                                                  <i className="bi bi-bag"></i>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="topUsrAreaPic-tx hoverTextFull">
                                              <span className="text-muted fs-14 line-height-2 mx-td-spn viewFullWordHover">
                                                {
                                                  item.allocation.device
                                                    .deviceIdentifier
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="userTblCol">
                                        <Checkbox
                                          checked={item.isSelected}
                                          onChange={(e) =>
                                            upsertSelectedDevice(item, e)
                                          }
                                          disabled={
                                            item.allocation.user?.id === user.id
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={toggleDialog}
        >
          {`${
            trans.fetchLabelKeyTranslation("CancelButton", "Cancel")
          }`}
        </button>
        <button
          className={`k-button k-button-md k-rounded-md bg-primary btn text-white
            ${disableAttach || loading ? "disabledBtn" : ""}`}
          onClick={attachDevice}
          disabled={disableAttach || loading}
        >
          {`${
            trans.fetchLabelKeyTranslation("SaveButton", "Save")
          }`}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default AddDeviceDialog;
