import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { Switch, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import "./UserProfile.scss";
import { PlatformUserDetail, TinyUser, User } from "../../../../../types/user";
import CustomUserGridInfo from "../../../../../components/custom/grid/CustomUserGridInfo";
import { Loader } from "@progress/kendo-react-indicators";
import { AccessPermissionEnum } from "../../../../../enums/accessPermissionEnum";

import useLocale from "../../../../../hooks/useLocale";
import { Dictionary } from "../../../../../types/Dictionary";
import useAuth from "../../../../../hooks/useAuth";
import useMasterData from "../../../../../hooks/useMasterData";
import usersService from "../../../../../services/users.service";
import { EmailValidator, NameValidator } from "../../../../../utils/validatorUtil";
import { Error } from "@progress/kendo-react-labels";
import {
  UpsertUser,
  UpsertUserDevices,
} from "../../../../../types/user/UpsertUser";
import AddDeviceDialog from "./AddDeviceDialog";
import DeviceDetails from "./DeviceDetails";
import { Customer } from "../../../../../types/customer";
import useSwal from "../../../../../hooks/useSwal";
import CustomComboBox from "../../../../../components/custom/form/CustomComboBox";
import { StorageDuration } from "../../../../../types/master-data";
import userNameRegexService from "../../../../../services/usernameRegex.service";
import useCustomNavigation from "../../../../../hooks/useCustomNavigation";
import useTranslation from "../../../../../hooks/useTranslation";

interface IUserProfileProps {
  user: User;
  customerInfo: Customer | undefined;
  setChanged: Function;
  updateUserDetails: () => void;
  currentUser: User;
}

interface IValidationProps {
  isEmailValid: boolean;
  isFirstNameValid: boolean;
  isLastNameValid: boolean;
}

const UserProfile: React.FC<IUserProfileProps> = ({
  user,
  customerInfo,
  setChanged,
  updateUserDetails,
  currentUser,
}) => {
  const isUserInNetworkOfLoggedInUser = currentUser.recordingNetwork?.find(
    (recUser: any) => recUser.id == user.id
  );
  const trans = useTranslation("UserProfile")
  const customNav=useCustomNavigation();
  const auth = useAuth();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const masterData = useMasterData();
  const scrollRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<User>(user);
  const [valuesChanged, setValuesChanged] = useState<boolean>(false);
  const [validation, setValidation] = useState<IValidationProps>({
    isEmailValid: true,
    isFirstNameValid: true,
    isLastNameValid: true,
  });
  const [addDeviceVisible, setAddDeviceVisible] =
    React.useState<boolean>(false);
  const [userNameRegex, setUserNameRegex] = useState<RegExp>();
  const [retentionPeriod, setRetentionPeriod] = useState<
    StorageDuration | undefined
  >(userDetails.retentionPeriod);
  const [showRetentionPeriodWarning, setShowretentionPeriodWarning] =
    useState<boolean>(false);
  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["UserProfile"]
    ) {
      trans.fetchTranslations("UserProfile");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    LoadUserNameRegex();
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [valuesChanged]);

  const LoadUserNameRegex = async () => {
    try {
      const data = await userNameRegexService.fetchUserNameRegex();
      setUserNameRegex(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  useEffect(() => {
    if (JSON.stringify(userDetails) === JSON.stringify(user)) {
      setValuesChanged(false);
      setChanged(false);
      customNav?.setIsChanged(false);
    } else {
      setValuesChanged(true);
      setChanged(true);
      customNav?.setIsChanged(true);
    }
  }, [userDetails]);
  const reset = () => {
    setUserDetails(user);
  };

  const getRetentionPeriodText = (duration: StorageDuration | undefined) => {
    if (duration === undefined || duration === null) {
      return "";
    } else {
      if (duration.durationType === "Unlimited") return duration.durationType;
      return duration.duration + " " + duration.durationType;
    }
  };

  const durationInDays = (duration: StorageDuration | undefined): Number => {
    if (duration === undefined || duration === null) {
      return Number.MAX_VALUE;
    }
    if (duration.durationType === "Unlimited") return Number.MAX_VALUE;
    else if (duration.durationType === "day") {
      return duration.duration;
    } else if (duration.durationType === "month") {
      return duration.duration * 30;
    } else if (duration.durationType === "year") {
      return duration.duration * 365;
    } else {
      return 0;
    }
  };

  const toggleAddDeviceDialog = () => {
    setAddDeviceVisible(!addDeviceVisible);
  };

  const handleUpdatedDeviceDetails = (details: PlatformUserDetail) => {
    var _devices: PlatformUserDetail[] = [];
    userDetails.devices.forEach((ele) => {
      if (ele.id === details.id) {
        _devices.push(details);
      } else {
        _devices.push(ele);
      }
    });
    setUserDetails({ ...userDetails, devices: _devices });
  };

  const onSaveClicked = async () => {
    if (!Object.values(validation).every((value) => value == true)) {
      Swal.fire({
        icon: "error",
        title: "Incorrect Fields",
        text: "Please fill all the fields correctly",
        confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
      });
    } else {
      setLoading(true);
      Promise.all([updateUserInfo()])
        .then((responses) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: trans.fetchLabelKeyTranslation("SwtAltUpdatedTitle", "Updated"),
            text: trans.fetchLabelKeyTranslation("SwtAltUpdate", "User has been updated"),
            confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
          });
          updateUserDetails();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (err.response.status === 403) {
            Swal.fire({
              icon: "error",
              title: trans.fetchLabelKeyTranslation("SwtAltErrorTitle", "Error"),
              text: trans.fetchLabelKeyTranslation("SwtAltEARegistered", "Email Already Register"),
              confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
            });
          } else if (err.response.status === 404) {
            Swal.fire({
              icon: "error",
              title: trans.fetchLabelKeyTranslation("SwtAltErrorTitle", "Error"),
              text: trans.fetchLabelKeyTranslation("SwtAltURFailed","User Registration failed"),
              confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
            });
          } else {
            Swal.fire({
              icon: "error",
              title: trans.fetchLabelKeyTranslation("SwtAltErrorTitle", "Error"),
              text: trans.fetchLabelKeyTranslation("SwtAltUpdateFailed","Error Updating User"),
              confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
            });
          }
        });
    }
  };

  const updateUserInfo = async () => {
    const upsertUser: UpsertUser = {
      email: userDetails.email,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      managerEmailAddress: userDetails.manager?.email,
      countryName: userDetails.country?.name,
      timezoneIdentifier: userDetails.timezone?.timezoneIdentifier,
      localeCode: userDetails.locale?.code,
      loginEnabled: userDetails.loginEnabled,
      devices: userDetails.devices.map((ele) => {
        var upsertDevice: UpsertUserDevices = {
          devicePlatform: ele.platform,
          deviceIdentifier: ele.deviceIdentifier,
          recordingPolicy: ele.recordingPolicy
            ? {
                isIncludedForRecording:
                  ele.recordingPolicy?.isIncludedForRecording,
                callSubsetTypeName:
                  ele.recordingPolicy?.callSubset?.name ?? "All Calls",
              }
            : undefined,
        };
        return upsertDevice;
      }),
      authorizationProfileName: userDetails.authorizationProfile?.name,
      recordingNetworkAccessibility:
        userDetails.recordingNetworkAccessibilty?.name,
      licensePackage: userDetails.licensePackage ? userDetails.licensePackage.name : "",
      retentionPeriod: getRetentionPeriodText(userDetails.retentionPeriod),
    };
    let updatedUser = await usersService
      .updateUser(upsertUser, user.id)
      .then((data) => {})
      .catch((err) => {
        throw err;
      });
  };
  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: itemProps.dataItem.loginUserId,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const itemChildren = (
      <div>
        <CustomUserGridInfo userInfo={userInfo} />
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };
  const itemRenderStorageDuration = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <div>{getRetentionPeriodText(itemProps.dataItem)}</div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };
  const valueRenderStorageDuration = (element: any) => {
    if (!element.props.value) {
      return element;
    }
    return (
      <span className="p-t-5 p-l-10" style={{ width: "100%" }}>
        {getRetentionPeriodText(retentionPeriod)}
      </span>
    );
  };

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    let noDataFoundError: string = "No data found";
    const count = customerInfo?.licensePackages?.filter(
      (l) => l.isAutoLearnEnabled || l.consumed < l.purchased
    ).length;
    if (!count)
      noDataFoundError =
        "All the licences have been utilized please contact your service provider or please purchase some more licences.";
    const noData = <h4 style={{ fontSize: "1em" }}>{noDataFoundError}</h4>;

    return React.cloneElement(element, { ...element.props }, noData);
  };

  return (
    <div className="tabInner">
      <div className="row">
        <div className="col-md-12">
          <div className="detailSection p-t-16 p-r-15 p-l-25">
            <div className="detailRow">
              <div className="row">
                <div className="col-md-4">
                  <div className="detailCol">
                    <div className="detailColTitle fs-15 text-default font-weight-semi p-b-5 m-b-10 border-bottom-solid border-w-1 border-black-1">
                      {trans.fetchLabelKeyTranslation(
                            "UserDetailsTitle",
                            "User Details"
                          )}
                    </div>
                    <div className="detailColBox">
                      <div className="formBox">
                        <div className="formBoxRow d-flex justify-content-between p-t-7 p-b-7">
                          <div className="formBoxLabel fs-14">
                            {trans.fetchLabelKeyTranslation(
                                  "EnableLoginTitle",
                                  "Enable Login"
                                )}
                          </div>
                          <div className="formBoxAction">
                            <div className="switchButton">
                              <Switch
                                checked={userDetails.loginEnabled}
                                onChange={(e) => {
                                  setUserDetails({
                                    ...userDetails,
                                    loginEnabled: e.value,
                                  });
                                }}
                                disabled={
                                  !auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageUsersAndGroups
                                  ) ||
                                  !isUserInNetworkOfLoggedInUser ||
                                  masterData?.telepoDisableConfig
                                    ?.usermanagement.users.profile
                                    .disableEnableLoginToggle
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="formBoxRow p-t-5 p-b-5">
                          <div className="formBoxLabel fs-14">
                            {trans.fetchLabelKeyTranslation("EmailTitle", "Email")}
                          </div>
                          <div className="formBoxAction">
                            <div className="formInput">
                              <Input
                                onBlur={() =>
                                  setValidation({
                                    ...validation,
                                    isEmailValid: EmailValidator(
                                      userDetails.email
                                    ),
                                  })
                                }
                                value={userDetails.email}
                                style={{ height: "32px" }}
                                onChange={(e) => {
                                  setUserDetails({
                                    ...userDetails,
                                    email: e.value,
                                  });
                                }}
                                disabled={
                                  !auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageUsersAndGroups
                                  ) ||
                                  !isUserInNetworkOfLoggedInUser ||
                                  masterData?.telepoDisableConfig
                                    ?.usermanagement.users.profile
                                    .disableEmailField
                                }
                              />
                              {!validation.isEmailValid ? (
                                <Error>
                                  Please provide a valid email address
                                </Error>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="formBoxRow p-t-5 p-b-5">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-col p-b-15">
                                <div className="form-group">
                                  <div className="formLabel fs-14 text-black-11 p-b-3">
                                    {trans.fetchLabelKeyTranslation(
                                          "FirstNameTitle",
                                          "First Name"
                                        )}
                                  </div>
                                  <div className="formControl">
                                    <Input
                                      onBlur={() =>
                                        userDetails.firstName &&
                                        userNameRegex &&
                                        setValidation({
                                          ...validation,
                                          isFirstNameValid: NameValidator(
                                            userDetails.firstName,
                                            userNameRegex
                                          ),
                                        })
                                      }
                                      value={userDetails.firstName}
                                      style={{ height: "32px" }}
                                      onChange={(e) => {
                                        setUserDetails({
                                          ...userDetails,
                                          firstName: e.value,
                                        });
                                      }}
                                      disabled={
                                        !auth?.checkUserAccess(
                                          AccessPermissionEnum.ManageUsersAndGroups
                                        ) ||
                                        !isUserInNetworkOfLoggedInUser ||
                                        masterData?.telepoDisableConfig
                                          ?.usermanagement.users.profile
                                          .disableNameField
                                      }
                                    />
                                    {!validation.isFirstNameValid ? (
                                      <Error>Remove invalid characters</Error>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-col p-b-15">
                                <div className="form-group">
                                  <div className="formLabel fs-14 text-black-11 p-b-3">
                                    {trans.fetchLabelKeyTranslation(
                                          "LastNameTitle",
                                          "Last Name"
                                        )}
                                  </div>
                                  <div className="formControl">
                                    <Input
                                      onBlur={() =>
                                        userDetails.lastName &&
                                        userNameRegex &&
                                        setValidation({
                                          ...validation,
                                          isLastNameValid: NameValidator(
                                            userDetails.lastName,
                                            userNameRegex
                                          ),
                                        })
                                      }
                                      value={userDetails.lastName}
                                      style={{ height: "32px" }}
                                      onChange={(e) => {
                                        setUserDetails({
                                          ...userDetails,
                                          lastName: e.value,
                                        });
                                      }}
                                      disabled={
                                        !auth?.checkUserAccess(
                                          AccessPermissionEnum.ManageUsersAndGroups
                                        ) ||
                                        !isUserInNetworkOfLoggedInUser ||
                                        masterData?.telepoDisableConfig
                                          ?.usermanagement.users.profile
                                          .disableNameField
                                      }
                                    />
                                    {!validation.isLastNameValid ? (
                                      <Error>Remove invalid characters</Error>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="formBoxRow p-t-5 p-b-5">
                          <div className="formBoxLabel fs-14">
                            {trans.fetchLabelKeyTranslation(
                                  "LineManagerTitle",
                                  "Line Manager"
                                )}
                          </div>
                          <div className="formBoxAction">
                            <div className="formInput">
                              <CustomComboBox
                                dataItemKey="id"
                                data={masterData?.users.filter(
                                  (u) => u.isArchived === false
                                )}
                                itemRender={itemRender}
                                value={
                                  userDetails.manager
                                    ? userDetails.manager
                                    : null
                                }
                                onChange={(e: ComboBoxChangeEvent) => {
                                  setUserDetails({
                                    ...userDetails,
                                    manager: e.value,
                                  });
                                }}
                                textField="email"
                                disabled={
                                  !auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageUsersAndGroups
                                  ) || !isUserInNetworkOfLoggedInUser
                                }
                                filtering={true}
                                filterFields={[
                                  "email",
                                  "firstName",
                                  "lastName",
                                ]}
                                sorting={true}
                                sortField={"email"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="formBoxRow p-t-5 p-b-5">
                          <div className="formBoxLabel fs-14">
                            {trans.fetchLabelKeyTranslation(
                                  "LicenceTitle",
                                  "Licence"
                                )}
                          </div>
                          <div className="formBoxAction">
                            <div className="formInput">
                              <CustomComboBox
                                dataItemKey="id"
                                data={customerInfo?.licensePackages
                                  ?.filter((l) => {
                                    return (
                                      l.isAutoLearnEnabled ||
                                      l.consumed < l.purchased
                                    );
                                  })
                                  .map((x) => {
                                    return masterData?.data?.licensePackages.find(y => y.id === x.id)
                                  })
                                }
                                 value={masterData?.data?.licensePackages.find(x => x.id === userDetails?.licensePackage?.id)}                                 
                                onChange={(e: ComboBoxChangeEvent) => {
                                  setUserDetails({
                                    ...userDetails,
                                    licensePackage: e.value,
                                    retentionPeriod: undefined
                                  });
                                }}
                                textField="localizationValue"
                                disabled={
                                  !auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageUsersAndGroups
                                  ) ||
                                  !isUserInNetworkOfLoggedInUser ||
                                  masterData?.telepoDisableConfig
                                    ?.usermanagement.users.profile
                                    .disableLicense
                                }
                                listNoDataRender={listNoDataRender}
                              />
                            </div>
                            {userDetails.licensePackage && (
                              <span style={{ fontSize: "10px", color: "#999" }}>
                                <i className="bi bi-info-circle"></i> &nbsp;
                                {trans.fetchLabelKeyTranslation(
                                      "DefaultRetentionPeriodTitle",
                                      "Default Retention Period: "
                                    )}{" "}
                                {getRetentionPeriodText(
                                  userDetails.licensePackage?.storageDuration
                                )}
                              </span>
                            )}
                            {userDetails.licensePackage && userDetails.devices.find((device:PlatformUserDetail)=>
                            device.platform==="MsTeams")?.deviceDetails.isRecordingPolicyAssigned===false && (
                              <div style={{ fontSize: "10px", color: "red" }}>
                                <i className="bi bi-exclamation-triangle"></i>&nbsp;
                                Microsoft Teams recording policy needs to be set for this user before calls can be recorded.
                              </div>
                            )}
                          </div>
                        </div>
                        {(userDetails.licensePackage?.baseLicense.id === 3) && (
                          <div className="formBoxRow p-t-5 p-b-5">
                            <div className="formBoxLabel fs-14">
                              {trans.fetchLabelKeyTranslation(
                                    "RetentionPeriodTitle",
                                    "Retention Period"
                                  )}
                            </div>
                            <div className="formBoxAction">
                              <div className="formInput">
                                <CustomComboBox
                                  dataItemKey="id"
                                  data={masterData?.data?.retentionPeriods}
                                  value={masterData?.data?.retentionPeriods.find(
                                    (x) =>
                                      x.id === userDetails.retentionPeriod?.id
                                  )}
                                  onChange={(e: ComboBoxChangeEvent) => {
                                    setUserDetails({
                                      ...userDetails,
                                      retentionPeriod: e.value,
                                    });
                                    if (e.value !== null) {
                                      if (
                                        durationInDays(
                                          userDetails.retentionPeriod
                                        ) > durationInDays(e.value)
                                      ) {
                                        setShowretentionPeriodWarning(true);
                                      } else {
                                        setShowretentionPeriodWarning(false);
                                      }
                                    } else {
                                      setShowretentionPeriodWarning(false);
                                    }
                                    setRetentionPeriod(e.value);
                                  }}
                                  textField="localizationValue"
                                  disabled={
                                    !auth?.checkUserAccess(
                                      AccessPermissionEnum.ManageUsersAndGroups
                                    ) ||
                                    !isUserInNetworkOfLoggedInUser ||
                                    masterData?.telepoDisableConfig
                                      ?.usermanagement.users.profile
                                      .disableRetentionPeriod
                                  }
                                />
                              </div>
                              {showRetentionPeriodWarning && (
                                <span
                                  style={{ fontSize: "10px", color: "red" }}
                                >
                                  <i className="bi bi-exclamation-circle-fill"></i>{" "}
                                  &nbsp;
                                  {trans.fetchLabelKeyTranslation(
                                        "DefaultRetentionPeriodWarningTitle",
                                        "Selected Retention Period is less then Default Retention Period"
                                      )}{" "}
                                  {getRetentionPeriodText(
                                    userDetails.retentionPeriod
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 cardLab">
                  <div className="detailColOuterLabel h-100 w-100 float-left p-l-15 border-l-1 border-solid-black border-left-solid border-left-w-1 border-black-1">
                    <div className="detailCol">
                      <div className="float-left w-100 p-l-10 p-r-10">
                        <div className="detailColTitle float-left w-100 fs-15 text-default font-weight-semi p-b-5 border-bottom-solid border-w-1 border-black-1">
                          <div className="d-flex justify-content-end align-items-center">
                            {(customerInfo?.platformDetails?.length ?? 0) > 1 &&
                              <Button
                                onClick={toggleAddDeviceDialog}
                                className={`btn bg-primary text-white fs-13 fw-normal 
                                ${
                                  !auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageUsersAndGroups
                                  ) ||
                                  !isUserInNetworkOfLoggedInUser ||
                                  masterData?.telepoDisableConfig?.usermanagement
                                    .users.profile.disableAttachDeviceButton
                                    ? "disabledBtn"
                                    : ""
                                }`}
                                style={{ height: "29px", margin: "-1px 0 0 0" }}
                                disabled={
                                  !auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageUsersAndGroups
                                  ) ||
                                  !isUserInNetworkOfLoggedInUser ||
                                  masterData?.telepoDisableConfig?.usermanagement
                                    .users.profile.disableAttachDeviceButton
                                }
                                title={`${
                                  trans.fetchLabelKeyTranslation(
                                        "AttachDeviceButtonTitle",
                                        "Attach Device"
                                      )
                                }`}
                              >
                                <i className="bi bi-plus fs-20"></i>
                                {trans.fetchLabelKeyTranslation(
                                      "AttachDeviceButtonTitle",
                                      "Attach Device"
                                    )}
                              </Button>
                            }  
                            {addDeviceVisible && (
                              <AddDeviceDialog
                                toggleDialog={toggleAddDeviceDialog}
                                userDevices={[...userDetails.devices]}
                                upsertDevice={(updateddevice) => {
                                  setUserDetails({
                                    ...userDetails,
                                    devices: updateddevice,
                                  });
                                }}
                                user={user}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="detailColBox float-left w-100">
                        <div className="formBox">
                          <div className="dvcList">
                            <div
                              className="listOverflowDvc float-left w-100 p-12"
                              style={{ maxHeight: "68vh", overflowY: "auto" }}
                            >
                              {userDetails.devices &&
                              userDetails.devices.length > 0 ? (
                                userDetails.devices.map((userDevice) => {
                                  return (
                                    <DeviceDetails
                                      key={userDevice.id}
                                      details={userDevice}
                                      updatedDeviceDetails={
                                        handleUpdatedDeviceDetails
                                      }
                                      isUserInNetworkOfLoggedInUser={
                                        isUserInNetworkOfLoggedInUser
                                      }
                                    />
                                  );
                                })
                              ) : (
                                <div className="col-md-12 p-l-5">
                                  <div className="dashedBox p-10 m-b-20 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center">
                                    <span className="fs-13 text-black-9 p-t-20 p-b-20">
                                      {trans.fetchLabelKeyTranslation(
                                            "NoDevicesAvailableTxt",
                                            "No Devices Available"
                                          )}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {valuesChanged && (
              <div ref={scrollRef} className="row">
                <div className="col-md-12">
                  <div className="float-left w-100 p-t-12 p-b-6 p-l-15 p-r-15 m-t-10 text-right border-top-solid border-w-1 border-black-1">
                    <Button
                      className={`btn bg-black-5 fs-15 padding-6 p-l-15 p-r-15 ${
                        loading ? "disabledBtn" : ""
                      }`}
                      disabled={loading}
                      onClick={() => reset()}
                    >
                      {`${
                        trans.fetchLabelKeyTranslation("CancelButton", "Cancel")
                      }`}
                    </Button>
                    <Button
                      className={`btn bg-primary fs-15 text-white padding-6 p-l-15 p-r-15 ${
                        loading ? "disabledBtn" : ""
                      }`}
                      disabled={loading}
                      onClick={onSaveClicked}
                    >
                      {loading ? (
                        <Loader
                          themeColor="light"
                          size="small"
                          type="infinite-spinner"
                        />
                      ) : (
                        `${
                          trans.fetchLabelKeyTranslation("SaveButtonText", "Save")
                        }`
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
