import { createContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import masterDataService from "../services/masterData.service";
import usersService from "../services/users.service";
import { MasterData } from "../types/master-data";
import { TinyUser, User } from "../types/user";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import { AccessPermissionListSequenceBuilder } from "../utils/stringUtils";
import { axiosWithAuth } from "../utils/customAxios";
import { TelepoDisableConfigProps } from "../types/master-data/TelepoDisableConfig";
import { EntityLocalizationConfiguration } from "../types/locale/Translation";
import localeService from "../services/locale.service";

type MasterDataContextType = {
  data?: MasterData;
  fetchMasterDataKeyTranslation: (
    keyGroup: string,
    key: string,
    defaultValue: string
  ) => string;
  users: TinyUser[];
  standardUsers: User[];
  fetchUpdatedUsers: () => Promise<void>;
  setStandardUsers: React.Dispatch<React.SetStateAction<User[]>>;
  telepoDisableConfig?: TelepoDisableConfigProps;
  fetchUpdatedStandardUsers: () => Promise<void>;
  entityLocalizationNameKeyMapping: EntityLocalizationConfiguration[];
  getEntityNameKeyfromConfigMap: (entityName: string, fieldName: string) => string;
};

const masterDataKeys: string[] = [
  "Platform",
  "License",
  "AccessPermission",
  "StorageMode",
  "Sentiment",
  "CallStatus",
  "ColorCategory",
  "RecordingNetworkAccessibilty",
  "CallDirection",
  "CallType",
  "LocalizationCode",
  "Country",
  "Timezone",
  "CallSubsetType",
  "AuditEntityType",
  "BatchJobType",
  "BatchJobPriority",
  "BatchJobState",
  "EvaluationRange",
  "TopicType",
  "RetentionPeriod",
  "AuditEntityActionType",
  "NotificationType",
  "CrmSystemType",
  "SharedRecordingEntity",
  "TeamsRecordingMode"
];

const MasterDataContext = createContext<MasterDataContextType | null>(null);

export const MasterDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useAuth();
  const localeCtx = useLocale();
  const [data, setData] = useState<MasterData>();
  const [users, setUsers] = useState<TinyUser[]>([]);
  const [entityLocalizationNameKeyMapping, setEntityLocalzationNameKeyMapping] =
    useState<EntityLocalizationConfiguration[]>([]);
  const [standardUsers, setStandardUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [masterDataTranslations, setMasterDataTranslations] = useState<
    Dictionary<Dictionary<string>> | undefined
  >(localeCtx?.selectedLocale?.current.masterDataTranslations);
  const [telepoDisableConfig, setTelepoDisableConfig] = useState<
    TelepoDisableConfigProps | undefined
  >();

  useEffect(() => {
    let isMounted = true;

    const getMasterData = async () => {
      try {
        isMounted && setIsLoading(true);
        const response = await masterDataService.getMasterData(masterDataKeys);
        mapLocalizationKeyValue(response);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    auth?.accessToken ? getMasterData() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, [auth?.accessToken,masterDataTranslations]);

  useEffect(() => {
    let isMounted = true;

    const fetchAppUsers = async () => {
      try {
        isMounted && setIsLoading(true);
        const response = await usersService.getAllTinyUsers();
        setUsers(response);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    const fetchEntityLocalizationNameKeyMapping = async () => {
      try {
        isMounted && setIsLoading(true);
        const response =
          await localeService.fetchEntityLocalizationNameKeyMapping();
        console.log(response);
        setEntityLocalzationNameKeyMapping(response);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    const fetchStandardUsers = async () => {
      try {
        isMounted && setIsLoading(true);
        const response = await usersService.fetchUsers();
        setStandardUsers(response);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    const fetchTelepoDisableConfig = async () => {
      try {
        isMounted && setIsLoading(true);
        const response = await axiosWithAuth.get(
          "/systemsetting/disableconfig"
        );
        setTelepoDisableConfig(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };
    fetchEntityLocalizationNameKeyMapping();
    auth?.accessToken ? fetchAppUsers() : setIsLoading(false);
    auth?.accessToken ? fetchStandardUsers() : setIsLoading(false);
    auth?.accessToken ? fetchTelepoDisableConfig() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, [auth?.accessToken]);

  useEffect(() => {
    if (
      localeCtx?.selectedLocale?.current.masterDataTranslations === undefined
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (data) {
      mapLocalizationKeyValue({ ...data });
    }
  }, [masterDataTranslations]);

  const mapLocalizationKeyValue = (masterData: MasterData) => {
    if (masterData !== undefined) {
      masterData.auditEntityTypes = masterData.auditEntityTypes.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "AuditEntityType",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.countries = masterData.countries.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "Country",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.timezones = masterData.timezones.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "Timezone",
          ele.localizationKey ?? "",
          ele.displayName
        );
        return ele;
      });
      masterData.platforms = masterData.platforms.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "Platform",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.retentionPeriods = masterData.retentionPeriods.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "RetentionPeriod",
          ele.localizationKey ?? "",
          ele.duration + " " + ele.durationType
        );
        return ele;
      });
      masterData.licensePackages = masterData.licensePackages.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "LicensePackage",
          ele.localizationKey ?? "",
          ele.name
        );
        if (ele.storageDuration) {
          ele.storageDuration.localizationValue = fetchMasterDataKeyTranslation(
            "RetentionPeriod",
            ele.storageDuration?.localizationKey ?? "",
            ele.storageDuration?.duration +
              " " +
              ele.storageDuration?.durationType
          );
        }
        return ele;
      });
      masterData.notificationTypes = masterData.notificationTypes.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "NotificationType",
          ele.localizationKey ?? "",
          ele.type
        );
        return ele;
      });
      // masterData.deviceLicenses = masterData.deviceLicenses.map((ele) => {
      //   ele.localizationValue = fetchMasterDataKeyTranslation(
      //     "License",
      //     ele.localizationKey ?? "",
      //     ele.licenseName ?? ""
      //   );
      //   return ele;
      // });
      masterData.accessPermissions = masterData.accessPermissions.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "AccessPermission",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.accessPermissions = AccessPermissionListSequenceBuilder(
        masterData.accessPermissions
      );
      masterData.storageModes = masterData.storageModes.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "StorageMode",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.sentiments = masterData.sentiments.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "Sentiment",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.callStatuses = masterData.callStatuses.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "CallStatus",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.colorCategories = masterData.colorCategories.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "ColorCategory",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.recordingNetworkAccessibilties =
        masterData.recordingNetworkAccessibilties.map((ele) => {
          ele.localizationValue = fetchMasterDataKeyTranslation(
            "RecordingNetworkAccessibilty",
            ele.localizationKey ?? "",
            ele.name
          );
          return ele;
        });
      masterData.callDirections = masterData.callDirections.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "CallDirection",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.callTypes = masterData.callTypes.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "CallType",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.callSubsetTypes = masterData.callSubsetTypes.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "CallSubsetType",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.evaluationRange = masterData.evaluationRange.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "EvaluationRange",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.topicType = masterData.topicType.map((ele) => {
        ele.localizationValue = fetchMasterDataKeyTranslation(
          "TopicType",
          ele.localizationKey ?? "",
          ele.name
        );
        return ele;
      });
      masterData.auditEntityActionType = masterData.auditEntityActionType.map(
        (ele) => {
          ele.localizationValue = fetchMasterDataKeyTranslation(
            "AuditEntityActionType",
            ele.localizationKey ?? "",
            ele.name
          );
          return ele;
        }
      );
      masterData.sharedRecordingEntities =
        masterData.sharedRecordingEntities.map((ele) => {
          ele.localizationValue = fetchMasterDataKeyTranslation(
            "SharedRecordingEntity",
            ele.localizationKey ?? "",
            ele.name
          );
          return ele;
        });
      setData(masterData);
    }
  };

  const fetchTranslations = async () => {
    try {
      const resp = await localeCtx?.setMasterDataTranslation(masterDataKeys);
      setMasterDataTranslations(resp);
    } catch (err) {
      console.error(err);
      setMasterDataTranslations(
        localeCtx?.selectedLocale?.previous.masterDataTranslations
      );
    } finally {
    }
  };

  const fetchMasterDataKeyTranslation = (
    keyGroup: string,
    key: string,
    defaultValue: string
  ): string => {
    return masterDataTranslations &&
      masterDataTranslations[keyGroup] &&
      masterDataTranslations[keyGroup][key]
      ? masterDataTranslations[keyGroup][key]
      : defaultValue;
  };

  const fetchUpdatedUsers = async () => {
    try {
      const response = await usersService.getAllTinyUsers();
      setUsers(response);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUpdatedStandardUsers = async () => {
    try {
      const response = await usersService.fetchUsers();
      setStandardUsers(response);
    } catch (err) {
      console.error(err);
    }
  };

  const getEntityNameKeyfromConfigMap = (
    entityName: string,
    fieldName: string
  ): string => {
    var ele = entityLocalizationNameKeyMapping.find(
      (x) =>
        x.entityName.toLowerCase() === entityName.toLowerCase() &&
        x.fieldName.toLowerCase() === fieldName.toLocaleLowerCase()
    );

    return ele === undefined ? "" : ele?.localizationKey;
  };

  return (
    <MasterDataContext.Provider
      value={{
        fetchUpdatedUsers,
        data,
        users,
        standardUsers,
        fetchMasterDataKeyTranslation,
        setStandardUsers,
        telepoDisableConfig,
        fetchUpdatedStandardUsers,
        entityLocalizationNameKeyMapping,
        getEntityNameKeyfromConfigMap
      }}
    >
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataContext;
