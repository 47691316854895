import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import useLocale from "../../hooks/useLocale";
import { Customer, PlatformDetail } from "../../types/customer";
import React from "react";
import useAuth from "../../hooks/useAuth";
import customerService from "../../services/customer.service";
import { AxiosError } from "axios";
import { CustomerPlatformStatus } from "../../types/customer/CustomerPlatformStatus";
import { Loader } from "@progress/kendo-react-indicators";
import {  useSearchParams } from "react-router-dom";
import { appUrl } from "../..";
import { GraphUrlStateDetails } from "../../types/customer/GraphUrlStateDetails";
import { MsTeamsProvisioningActionEnum } from "../../enums/MsTeamsProvisioningActionEnum";
import { DeviceAllocation } from "../../types/device/deviceAllocation";
import deviceService from "../../services/device.service";
import useTranslation from "../../hooks/useTranslation";
import RecordedUserDialogBox from "./RecordedUserDialogBox";
import { Buffer } from "buffer";

interface MsTeamsProps {
  platformDetail: PlatformDetail;
}

const adminConsentUrlTemplate = "https://login.microsoftonline.com/common/adminconsent?client_id={client_id}&redirect_uri={redirect_uri}&scope={scope}&state={state}";
const authCodeUrlTemplate = "https://login.microsoftonline.com/{tenant_id}/oauth2/v2.0/authorize?client_id={client_id}&response_type=code&redirect_uri={redirect_uri}&response_mode=query&scope={scope}&state={state}";

const MsTeams: React.FC<MsTeamsProps> = ({ platformDetail }) => {
  const localeCtx = useLocale();
  const auth = useAuth();
  const trans = useTranslation("CustomerPlatformMsTeams");
  const [loading, setLoading] = useState<boolean>(false);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(false);
  const [platformStatus, setPlatformStatus] = useState<CustomerPlatformStatus | null>(null);
  const [msTeamsDevices, setMsTeamsDevices] = useState<DeviceAllocation[]>([]);
  const [customerInfo, setCustomerInfo] = useState<Customer | undefined>(undefined);
  const [isPolicyAssignmentValid, setIsPolicyAssignmentValid] = useState<boolean>(true);
  const [onboardingStep, setOnboardingStep] = useState<number>(1);
  const [userDialogVisible, setUserDialogVisible] = useState<boolean>(false);
  const [adminConsentUrl, setAdminConsentUrl] = useState<string | null>(null);
  const [authCodeUrl, setAuthCodeUrl] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const actionType = searchParams.get("actionType");

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["CustomerPlatformMsTeams"]) {
      trans.fetchTranslations("CustomerPlatformMsTeams")
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getCustomerInfo();
    getCustomerPlatformStatus();
    getDevices();
  }, []);

  useEffect(() => {
    checkIsPolicyAssignmentValid();
  }, [customerInfo, msTeamsDevices]);

  const getCustomerPlatformStatus = async () => {
    setLoading(true);
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        const _platformStatus = await customerService.getCustomerPlatformStatus(customerId, "MsTeams");
        setPlatformStatus(_platformStatus);
        if (_platformStatus.platformStatusHistory.find(history => history.statusName === "MsAdminConsentGranted") === undefined) {

          if (_platformStatus.customerOnboardingDetail.azureAppClientId !== undefined &&
            _platformStatus.customerOnboardingDetail.msTeamsAdminConsentUrlScope !== undefined) {
            let urlState: GraphUrlStateDetails = {
              customerId: customerId,
              platform: "MsTeams",
              msTeamsProvisioningAction: MsTeamsProvisioningActionEnum.GrantMsAdminConsent
            };
            let encodedUrlState = Buffer.from(JSON.stringify(urlState)).toString("base64");
            let _adminConsentUrl = adminConsentUrlTemplate;
            _adminConsentUrl = _adminConsentUrl.replace("{client_id}", encodeURIComponent(_platformStatus.customerOnboardingDetail.azureAppClientId));
            _adminConsentUrl = _adminConsentUrl.replace("{scope}", encodeURIComponent(_platformStatus.customerOnboardingDetail.msTeamsAdminConsentUrlScope));
            _adminConsentUrl = _adminConsentUrl.replace("{redirect_uri}", encodeURIComponent(appUrl + '/customer-platform/admin-action'));
            _adminConsentUrl = _adminConsentUrl.replace("{state}", encodeURIComponent(encodedUrlState));
            setAdminConsentUrl(_adminConsentUrl);
          }
          setOnboardingStep(1);
        }
        else if (_platformStatus.platformStatusHistory.find(history => history.statusName === "TeamsRecordingPolicyCreated") === undefined) {
          if (_platformStatus.customerOnboardingDetail.azureAppClientId !== undefined &&
            _platformStatus.customerOnboardingDetail.tenantGUID !== undefined &&
            _platformStatus.customerOnboardingDetail.msTeamsAccessCodeUrlScope !== undefined) {
            let urlState: GraphUrlStateDetails = {
              customerId: customerId,
              platform: "MsTeams",
              msTeamsProvisioningAction: MsTeamsProvisioningActionEnum.CreateRecordingPolicy
            };
            let encodedUrlState = Buffer.from(JSON.stringify(urlState)).toString("base64");
            let _authCodeUrl = authCodeUrlTemplate;
            _authCodeUrl = _authCodeUrl.replace("{tenant_id}", encodeURIComponent(_platformStatus.customerOnboardingDetail.tenantGUID));
            _authCodeUrl = _authCodeUrl.replace("{client_id}", encodeURIComponent(_platformStatus.customerOnboardingDetail.azureAppClientId));
            _authCodeUrl = _authCodeUrl.replace("{scope}", encodeURIComponent(_platformStatus.customerOnboardingDetail.msTeamsAccessCodeUrlScope));
            _authCodeUrl = _authCodeUrl.replace("{redirect_uri}", encodeURIComponent(appUrl + '/customer-platform/admin-action'));
            _authCodeUrl = _authCodeUrl.replace("{state}", encodeURIComponent(encodedUrlState));
            setAuthCodeUrl(_authCodeUrl);
          }
          setOnboardingStep(2);
        }
        else if (_platformStatus.platformStatusHistory.find(history => history.statusName === "RecordingPolicyAssigned") === undefined) {
          setOnboardingStep(3);
        }
        else {
          setOnboardingStep(4);
        }
        if(actionType==="assign-license" && _platformStatus.platformStatusHistory.find((history)=>history.statusName==="PlatformActive")){
          toggleUserDialog();
        }
      } catch (err) {
        console.log(err);
        if (err instanceof AxiosError) {
          setPlatformStatus(null);
        }
      }
      finally {
        setLoading(false);
      }
    }
  }

  const getDevices = async () => {
    setDevicesLoading(true);
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        const _devices = await deviceService.getAllCustomerDevice("MsTeams");
        setMsTeamsDevices(_devices);
      } catch (err) {
        setMsTeamsDevices([]);
      }
      finally {
        setDevicesLoading(false);
      }
    }
  }

  const getCustomerInfo = async () => {
    var customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        var customer = await customerService.getCustomerDetailsById(customerId);
        setCustomerInfo(customer);
      } catch (ex) {
        console.log(ex);
      } finally {
      }
    }
  };

  const grantMsTeamsAdminConsent = async () => {
    if (adminConsentUrl !== null) {
      window.location.replace(adminConsentUrl);
    }
  }

  const createMsTeamsRecordingPolicy = async () => {
    if (authCodeUrl !== null) {
      window.location.replace(authCodeUrl);
    }
  }

  const toggleUserDialog = () => {
    getDevices();
    setUserDialogVisible(!userDialogVisible);
  };

  const checkIsPolicyAssignmentValid = () => {
    if(customerInfo && customerInfo.licensePackages && customerInfo.licensePackages.length > 0){
      let licenseInfo = customerInfo.licensePackages;
      if(licenseInfo.findIndex(l => l.isAutoLearnEnabled === true) !== -1){
        setIsPolicyAssignmentValid(true)
      }
      else{
        let totalLicenseCount = licenseInfo.map(l => {return l.purchased}).reduce((partialSum, a) => partialSum + a, 0);
        let consumedLicense = msTeamsDevices.filter(d => d.device.deviceDetails.isRecordingPolicyAssigned).length;
        setIsPolicyAssignmentValid(consumedLicense <= totalLicenseCount)
      }
    }else{
      setIsPolicyAssignmentValid(false);
    }
  }

  return (
    loading ? (
      <div className="col-md-12 d-flex justify-content-center align-items-center">
        <Loader type={"infinite-spinner"} />
      </div>
    ) : (
      <div>
        <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
          <span className="text-primary">
            {trans?.fetchLabelKeyTranslation("PlatformTitle", "MsTeams Platform")}
          </span>
        </div>
        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
          <div className="trkCol">
            <div className="trkCol-h font-weight-semi">
              {trans?.fetchLabelKeyTranslation("FieldTenantId", "Tenant Id")}
            </div>
            <div className="trkCol-dot">:</div>
            <div className="trkCol-p">{platformDetail.customerDetail.msTeamsTenantsGuid}</div>
          </div>
        </div>
        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
          <div className="trkCol">
            <div className="trkCol-h font-weight-semi">
              {trans?.fetchLabelKeyTranslation("FieldAdminEmail", "Admin Email")}
            </div>
            <div className="trkCol-dot">:</div>
            <div className="trkCol-p">{platformDetail.customerDetail.msTeamsAdminEmailAddress}</div>
          </div>
        </div>
        <div className="row p-t-20">
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <div className="stepMain">
              <div className="stepFlow">
                <div className="stepFlowTitle float-left w-100">
                  <span className="trk-title text-primary">
                  {trans?.fetchLabelKeyTranslation("CallRecordingSetupTitle", "Setup Call Recording")}
                  </span>
                </div>
                <div className="stepFlowGroup">
                  <div className="stepFlowRow">
                    <div className={`stepFlowCol ${onboardingStep === 1 ? "step-current" : (onboardingStep > 1 ? "step-active" : "")}`}>
                      <div className="stepColumn">
                        <div className="stepCircle">
                          <div className="stepCircleBdr">
                            <div className="stepCircleBox">
                              <div className="stepCircleIcon">
                                {onboardingStep > 1 && <i className="bi bi-check-lg"></i>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="stepLabelRow">
                          <div className="stepRowText">
                            <div className="stepLabel">
                            {trans?.fetchLabelKeyTranslation("SetupStepOneTitle", "Microsoft Teams Admin Consent")}
                            </div>
                            <div className="stepLabelDetails">
                            {trans?.fetchLabelKeyTranslation("SetupStepOneDesc", "Microsoft Teams admin consent refers to the process by which administrators grant permissions for Recording Bot applications or services to access and integrate with Microsoft Teams on behalf of your organization. Recording Bot applications require specific permissions to interact with Microsoft Teams APIs and access certain features or data.")}
                            </div>
                          </div>
                          {onboardingStep === 1 &&
                            <div className="stepBtn float-right d-flex align-items-center">
                              <Button
                                className={`btn bg-primary text-white`}
                                onClick={grantMsTeamsAdminConsent}
                              >
                                {trans?.fetchLabelKeyTranslation("SetupStepOneBtnText", "Grant Consent")}
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`stepFlowCol ${onboardingStep === 2 ? "step-current" : (onboardingStep > 2 ? "step-active" : "")}`}>
                      <div className="stepColumn">
                        <div className="stepCircle">
                          <div className="stepCircleBdr">
                            <div className="stepCircleBox">
                              <div className="stepCircleIcon">
                                {onboardingStep > 2 && <i className="bi bi-check-lg"></i>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="stepLabelRow">
                          <div className="stepRowText">
                            <div className="stepLabel">
                            {trans?.fetchLabelKeyTranslation("SetupStepTwoTitle", "Create Recording policy for your organization")}
                            </div>
                            <div className="stepLabelDetails">
                            {trans?.fetchLabelKeyTranslation("SetupStepTwoDesc", "Create recording bot user for your organization to attend calls as silent user to record calls, and create Microsoft Teams recording policy for your organization to assign to users.")}
                            </div>
                          </div>
                          {onboardingStep === 2 &&
                            <div className="stepBtn float-right d-flex align-items-center">
                              <Button
                                className={`btn bg-primary text-white`}
                                onClick={createMsTeamsRecordingPolicy}
                              >
                                {trans?.fetchLabelKeyTranslation("SetupStepTwoBtnText", "Create Policy")}
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`stepFlowCol ${onboardingStep === 3 ? "step-current" : (onboardingStep > 3 ? "step-active" : "")}`}>
                      <div className="stepColumn">
                        <div className="stepCircle">
                          <div className="stepCircleBdr">
                            <div className="stepCircleBox">
                              <div className="stepCircleIcon">
                                {onboardingStep > 3 && <i className="bi bi-check-lg"></i>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="stepLabelRow">
                          <div className="stepRowText">
                            <div className="stepLabel">
                            {trans?.fetchLabelKeyTranslation("SetupStepThreeTitle", "Select Users to be Recorded")}
                            </div>
                            <div className="stepLabelDetails">
                            {trans?.fetchLabelKeyTranslation("SetupStepThreeDesc", "Manage recorded users by assigning or removing Microsoft Teams recording policy.")}
                            </div>
                          </div>
                          {onboardingStep >= 3 &&
                            <div className="stepBtn float-right d-flex align-items-center">
                              <Button className="btn bg-primary text-white" onClick={toggleUserDialog}>
                                {onboardingStep === 3 ? "Select Users"
                                : `${trans?.fetchLabelKeyTranslation("SetupStepThreeBtnText", "Recorded Users")} (${msTeamsDevices.filter(d => d.device.deviceDetails.isRecordingPolicyAssigned).length})`
                                }
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stepLine"></div>
                </div>
                {userDialogVisible && (
                  <RecordedUserDialogBox
                  devicesLoading={devicesLoading}
                  toggleUserDialog={toggleUserDialog}
                  msTeamsDevicesConstant={msTeamsDevices}
                  customerInfo={customerInfo}
                  isPolicyAssignmentValid={isPolicyAssignmentValid}
                  platformStatus={platformStatus}
                  authCodeUrlTemplate={authCodeUrlTemplate}
                  />
                )}
              </div>
            </div>
          </div>
          {onboardingStep > 3 && 
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <div className="fs-14 tx-green m-t-10 fw-bold">
              {trans?.fetchLabelKeyTranslation("RecordingActiveText", "You are up and running! Recording is now active for calls with selected users. Feel free to close this browser window.")}
            </div>
          </div>
          }
        </div>
      </div>
    )
  );
};

export default MsTeams;
