import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usersService from "../../services/users.service";
import { Dictionary } from "../../types/Dictionary";
import {
  InsightMetricKeyLabel,
  InsightViewByTblData,
} from "../../types/insight";
import { TinyUser } from "../../types/user";
import {
  concatUsername,
} from "../../utils/profileUtils";
import useSwal from "../../hooks/useSwal";
import useTranslation from "../../hooks/useTranslation";
import { removeAllSpaces } from "../../utils/stringUtils";
import { EntityKey } from "../../types/master-data/EntityKey";
import { processMetricKeyForDisplay } from "./InsightTranslationKeys";

interface CallColorCountPair {
  color: string;
  count: number;
}

interface InsightViewChartProps {
  viewFilterTblData?: InsightViewByTblData;
  viewByDataLoading: boolean;
  viewByMetric: InsightMetricKeyLabel;
}
interface IColors extends EntityKey {
  id: number;
  title: string;
}

const colors: [IColors, IColors, IColors, IColors, IColors, IColors] = [
  {
    id: 1,
    title: "Yellow",
    localizationKey: "Yellow"
  },
  {
    id: 2,
    title: "Red",
    localizationKey: "Red"
  },
  {
    id: 3,
    title: "Green",
    localizationKey: "Green"
  },
  {
    id: 4,
    title: "Orange",
    localizationKey: "Orange"
  },
  {
    id: 5,
    title: "Blue",
    localizationKey: "Blue"
  },
  {
    id: 6,
    title: "Purple",
    localizationKey: "Purple"
  }
];

const colors2 = ["#5CB0BA"];

interface ITranslationDict {
  category: string;
  translatedCategory: string;
}

const InsightViewChart: React.FC<InsightViewChartProps> = ({
  viewFilterTblData,
  viewByDataLoading,
  viewByMetric,
}) => {
  const trans = useTranslation("InsightViewChart");
  const localeCtx = useLocale();
  const swal = useSwal();

  const [translationDict, setTranslationDict] = useState<ITranslationDict[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [labelCounts, setLabelCounts] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshChart, setRefreshChart] = useState<boolean>(false);

  useEffect(() => {
    const buildChartData = async () => {
      setLabels([]);
      setLabelCounts([]);
      if (viewFilterTblData) {
        if (
          viewFilterTblData.rows.length > 0 &&
          viewFilterTblData.rows[0].cells.length > 0
        ) {
          setLoading(true);
          const filterdRows = viewFilterTblData.rows.filter((row) => row.cells[0].metricKeyCounts[0].totalCount > 0);
          viewFilterTblData.rows = [...filterdRows]

          const keys: string[] = [];
          const values: number[] = [];
          const colorCountPair: CallColorCountPair[] = [];
          const uniqueUserIds: string[] = [];
          if (viewByMetric.name === "Users" || viewByMetric.name === "Evaluators") {
            viewFilterTblData.rows.forEach((r) => {
              const id = r.cells[0].metricKeyCounts[0].metricKey;
              if (!uniqueUserIds.includes(id)) {
                uniqueUserIds.push(id);
              }
            });
          }

          let tinyUsers: { [key: string]: TinyUser } = {};
          let error = false;
          try {
            const res = await usersService.getTinyUsers(uniqueUserIds);
            tinyUsers = res;
          } catch (err) {
            console.error(err);
            error = true;
          }

          let translationMapping: ITranslationDict[] = [];
          viewFilterTblData.rows.forEach((r) => {
            const metric =
              r.cells[0].metricKeyCounts.length > 0
                ? r.cells[0].metricKeyCounts[0]
                : { metricKey: "", totalCount: 0 };
            if (viewByMetric.name !== "Users" && viewByMetric.name !== "Evaluators") {
              const metricKeyObj = processMetricKeyForDisplay(viewByMetric.name, metric.metricKey, false);
              let translatedKey = trans.fetchLabelKeyTranslation(metricKeyObj.localizationKey, metricKeyObj.title);
              translatedKey = metricKeyObj.trim ? translatedKey.substring(0, 3) : translatedKey;
              keys.push(translatedKey);
              if(viewByMetric.name === "Sentiments"){
                translationMapping.push({category: metricKeyObj.title, translatedCategory: translatedKey});
              }
            } else {
              if (!error) {
                const userInfo = tinyUsers[metric.metricKey];
                const name = concatUsername(
                  userInfo?.firstName ? userInfo.firstName : "",
                  userInfo?.lastName ? userInfo?.lastName : ""
                ).trim();
                keys.push(name.length > 0 ? name : userInfo.email);
              }
            }
            values.push(metric.totalCount);
            colorCountPair.push({
              color: metric.metricKey,
              count: metric.totalCount,
            });
          });
          setTranslationDict(translationMapping);
          if (viewByMetric.displayName === "Flags") {
            const translatedColors = colors.map((color: IColors) => trans.fetchLabelKeyTranslation(color.localizationKey ?? "", color.title))
            setLabels(translatedColors);
            const dict = colors.map((color, index) => ({
              category: color?.title,
              translatedCategory: translatedColors[index]
            }));
            setTranslationDict(dict);

            const countForFlags: number[] = [];
            colors.forEach((color: IColors) => {
              const colorCountPairValue = colorCountPair.find((pair: CallColorCountPair) => pair.color === color.title);
              if (colorCountPairValue) {
                countForFlags.push(colorCountPairValue.count);
              } else {
                countForFlags.push(0);
              }
            });
            setLabelCounts(countForFlags);
          } else {
            setLabels(
              viewByMetric.displayName === "Moments" ||
                viewByMetric.displayName === "Topics"
                ? keys.map((key) => key.substring(4))
                : keys
            );
            setLabelCounts(values);
          }
          setLoading(false);
          setRefreshChart(true);
        }
      } else {
        setRefreshChart(false);
      }
    };

    buildChartData();
  }, [viewFilterTblData, trans.translationsLoading]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
      "InsightViewChart"
      ]
    ) {
      trans.fetchTranslations("InsightViewChart");
    }
  }, [localeCtx?.selectedLocale]);

  const handleChartRefresh = (
    chartOptions: any,
    themeOptions: any,
    chartInstance: any
  ) => {
    if (refreshChart) {
      chartInstance.setOptions(chartOptions, themeOptions);
    }
  };

  const getTranslatedCategory = (val: string) => {
    const value = translationDict.find(obj => obj.translatedCategory === val)?.category ?? val;
    return value;
  }

  const getColor = (point: any) => {
    if (viewByMetric.displayName == "Sentiments") {
      const translatedVal = getTranslatedCategory(point.category);
      switch (translatedVal) {
        case "Negative": return "#dc3545";
        case "Neutral": return "#ffc107";
        case "Positive": return "#28a745";
        default: return "#5CB0BA";
      }
    } else if (viewByMetric.displayName == "Flags") {
      const translatedVal = getTranslatedCategory(point.category);
      switch (translatedVal) {
        case "Blue": return "#0000ff";
        case "Red": return "#dc3545";
        case "Yellow": return "#ffff00";
        case "Green": return "#07d11b";
        case "Orange": return "#ffa500";
        case "Purple": return "#a020f0";
        default: return "#5cb0ba";
      }
    } else return "#5CB0BA";
  };

  const callsByText: string = `Calls by ${viewByMetric.displayName}`;
  const callsByTextKey: string = `Callsby${removeAllSpaces(viewByMetric.displayName)}`;

  return (
    <>
      {viewByDataLoading || loading ? (
        <div
          className="radius-6 border-black-3 border-w-2 border-dashed"
          style={{
            height: 320,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type={"infinite-spinner"} themeColor={"primary"} />
          <span></span>
        </div>
      ) : (
        <Chart
          style={{ height: 320 }}
          onRefresh={handleChartRefresh}
          seriesColors={colors2}
        >
          <ChartLegend position={"bottom"} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              labels={{ rotation: "auto" }}
              categories={labels}
            />
          </ChartCategoryAxis>
          <ChartTooltip />
          <ChartSeries>
            <ChartSeriesItem
              name={
                trans.fetchLabelKeyTranslation(callsByTextKey, callsByText)
              }
              data={labelCounts}
              color={getColor}
            />
          </ChartSeries>
        </Chart>
      )}
    </>
  );
};

export default InsightViewChart;
